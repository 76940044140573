.sign-in-page,
.site-my-mac-v1 {
  .social-login {
    &__email-opt-in {
      @include breakpoint($bp--medium-up) {
        padding-top: 0;
      }
      @include breakpoint($bp--xxlarge-up) {
        padding-#{$ldirection}: 51px;
      }
    }
  }
  .gnav {
    .social-login {
      &__email-opt-in {
        .picker-handle {
          margin: 0;
        }
        .picker-label {
          margin: 0 0 0 30px;
        }
      }
      &__divider {
        .inner {
          padding: 5px 6px;
        }
      }
    }
  }
}
/* SignIn page styling */
.sign-in-page {
  @include breakpoint($bp--medium-up) {
    .social-login-section {
      margin-top: 110px;
      margin-#{$ldirection}: 50px;
      @include breakpoint($bp--xxlarge-up) {
        margin-#{$ldirection}: 0;
      }
      .picker-label {
        margin-#{$ldirection}: 0;
      }
    }
  }
  .social-login-section {
    .social-login {
      &__divider {
        .inner {
          padding: 5px 6px;
          @include breakpoint($bp--large-up) {
            #{$ldirection}: 96.1%;
            padding: 7px 3px;
            border-radius: 25px;
          }
        }
      }
      &__opt-in-label {
        margin-top: 9px;
      }
      @include breakpoint($bp--medium-up) {
        .disclaimer {
          display: none;
        }
      }
      .fb-overlay-container {
        margin: 20px auto;
      }
    }
    .social-terms__container {
      .disclaimer {
        margin-left: 51px;
        display: none;
        @include breakpoint($bp--medium-up) {
          display: block;
        }
      }
    }
  }
  .divider {
    .social-login__divider {
      .inner {
        padding: 5px 6px;
        @include breakpoint($bp--medium-up) {
          // Altered (or) text divider, to display at center.
          #{$rdirection}: -23px;
        }
      }
    }
  }
}
/* Checkout page styling */
.checkout {
  &__content {
    .signin-panel,
    .sign-in-panel {
      .social-login {
        &__opt-in-label {
          margin: 0 0 0 39px;
          text-align: $ldirection;
          display: block;
          float: none;
        }
        &__divider {
          .inner {
            padding: 5px 6px;
          }
        }
        .picker.picker-checkbox {
          .picker-handle {
            margin-top: 0;
          }
        }
      }
    }
  }
}
/* Appointment booking */
.appt-book,
.my-appointments {
  .social-login {
    &__email-opt-in {
      margin-top: 20px;
      padding: 0;
    }
    #facebook__email-opt-in-register-panel {
      margin-top: 23px;
      float: left;
    }
    &__opt-in-label {
      display: inline-block;
      margin: 0;
    }
    .fb-disclaimer {
      margin: 10px 0;
    }
    .picker-checkbox {
      display: flex;
      text-align: $ldirection;
      @include breakpoint($bp--medium-up) {
        .social-login__opt-in-label {
          margin-top: 9px;
        }
      }
    }
  }
}

.field-collection-slides {
  .appt-book {
    .appt-book-sections-container {
      .appt-book-section {
        .sign-in-form-container {
          .social-login {
            &__opt-in-label {
              margin-top: 9px;
            }
            label {
              margin-#{$ldirection}: 0;
            }
          }
        }
      }
    }
  }
}
/* Profile preferences page */
.social-info {
  padding-#{$ldirection}: 9px;
  &__connect {
    .fb-login-button {
      vertical-align: middle;
    }
  }
}
/* Write a review page styling */
#power_review_container {
  .social-login {
    .picker-label {
      margin-top: 0;
    }
  }
}
/* Order Confirmation Page */
#confirm {
  .confirm.checkout {
    .social-login {
      &__title {
        font-size: 18px;
        margin-bottom: 15px;
        @include breakpoint($bp--medium-up) {
          font-size: 32px;
        }
      }
      &__info {
        display: block;
        text-align: $ldirection;
      }
      &__divider {
        display: none;
      }
      &__terms {
        display: none;
      }
      &__email-opt-in {
        .picker-checkbox {
          display: flex;
          margin-top: 20px;
          .picker-handle {
            margin-top: 0;
          }
          .social-login__opt-in-label {
            text-align: $ldirection;
            margin-#{$ldirection}: 0;
          }
        }
      }
    }
  }
}

.checkout-confirmation-page {
  .social-login {
    .tooltip {
      display: block;
    }
    .picker.picker-checkbox {
      .picker-handle {
        margin-top: 0;
      }
    }
  }
}

.social-login.gnav {
  .tooltip {
    .tooltiptext-over {
      height: 95px;
      text-align: justify;
    }
  }
}
