.column-main {
  .appt-book {
    .confirmation-container,
    .my-appointments .appointment-container {
      .date-time {
        .appt-date-cal {
          background-position: 14em 0.3em;
        }
      }
    }
  }
}
