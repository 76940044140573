@if $loyalty-redesign == true {
  .site-header__wrapper {
    .site-header-formatter {
      .site-my-mac-v1 {
        .site-my-mac__contents {
          width: 450px;
          height: auto;
          overflow: hidden;
        }
        .site-my-mac__contents-title {
          margin-bottom: 10px;
          &__alt {
            font-size: 26px;
            font-family: $ano-bold_regular-font;
            a {
              text-transform: uppercase;
              border-bottom: none;
            }
          }
        }
        .site-my-mac__contents-header {
          .icon--remove {
            font-size: 35px;
          }
        }
        .site-my-mac__contents-signed_in {
          .site-my-mac__contents-account {
            li {
              margin: 0 0 10px 0;
              a {
                font-family: $helvetica-font;
                font-size: 20px;
                border-bottom-color: $color--black;
              }
            }
          }
          .site-my-mac__contents-footer {
            color: $color--black;
            &:hover {
              background: none;
              color: $color--black;
            }
            .site-my-mac__contents-footer-link,
            .site-my-mac__contents-footer-link2 {
              position: static;
              bottom: 0;
              font-family: $helvetica-font;
              border-bottom: none;
              text-decoration: underline;
              &:hover,
              &:visited,
              &:focus,
              &:active,
              &:not(:focus) {
                color: $color--black;
                border-bottom: none;
                font-size: 18px;
              }
              &:hover {
                border-bottom: none;
              }
            }
            .site-my-mac__contents__list {
              color: $color--black;
              padding: 44px 0 10px;
              li {
                color: $color--black;
                padding-bottom: 12px;
              }
            }
            .loyalty {
              &__level__details {
                width: 64%;
                .level__number {
                  font-family: $ano-bold_regular-font;
                  font-size: 23px;
                  padding-top: 10px;
                }
              }
              &__level__details,
              &__points__details {
                float: #{$ldirection};
                margin-top: 22px;
                .mac-select__color {
                  &-tier1,
                  &-tier2,
                  &-tier3 {
                    font-size: 32px;
                    letter-spacing: -0.02em;
                    font-family: $ano-bold_regular-font;
                    line-height: 0.8;
                  }
                  &-tier1 {
                    color: $color--pink-tier1;
                  }
                  &-tier2 {
                    color: $color--red-tier2;
                  }
                  &-tier3 {
                    color: $color--gray-tier3;
                  }
                }
              }
              &__points__details {
                width: 36%;
                text-align: #{$rdirection};
                font-family: $ano-bold_regular-font;
                .points__value {
                  font-size: 45px;
                  line-height: 0.8;
                }
                .points__content {
                  font-size: 23px;
                  letter-spacing: -0.02em;
                }
              }
            }
          }
        }
      }
    }
  }
}
