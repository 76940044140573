@if $loyalty-redesign == true {
  #loyalty__panel__transactions {
    @include breakpoint($bp--large-up) {
      margin: 8% auto 0;
      padding: 5% 4% 0;
      border-top: 1px solid $color--dark-gray;
    }
    margin: 60px 20px 0;
    padding: 10px 0 0;
    .loyalty__panel__transactions {
      &-spent {
        &__balance {
          display: none;
        }
      }
      &__title {
        @include breakpoint($bp--large-up) {
          font-size: 28px;
        }
        font-family: $ano-bold_regular-font;
        text-align: center;
        text-transform: uppercase;
        font-size: 23px;
      }
      &__subtitle {
        padding-top: 5px;
        text-align: center;
        font-family: $helvetica-font;
        font-size: 13px;
      }
      &__description {
        text-align: center;
        font-family: $helvetica-font;
        font-size: 13px;
      }
      &__points__available {
        @include breakpoint($bp--large-up) {
          padding-bottom: 30px;
        }
        padding-bottom: 10px;
        text-align: center;
        font-family: $helvetica-font;
        font-size: 13px;
        .points__content {
          text-transform: lowercase;
        }
      }
      &__list {
        table,
        tr,
        td {
          border-top: 1px solid $color--gray--lighter;
          border-bottom: 1px solid $color--gray--lighter;
          border-#{$ldirection}: none;
          border-#{$rdirection}: none;
        }
        .loyalty__panel__transactions {
          &__transaction {
            font-family: $roboto-mono_medium-font;
            font-size: 13px;
          }
          &__rewards-history {
            min-height: 0;
          }
          &__title {
            &.points__history__title {
              @include breakpoint($bp--large-up) {
                font-size: 28px;
              }
              font-family: $roboto-mono_medium-font;
              font-size: 20px;
              text-align: center;
              border: none;
              letter-spacing: 0.5px;
            }
          }
          &__table {
            thead {
              background: none;
              tr {
                border-bottom: none;
              }
              th {
                @include breakpoint($bp--large-up) {
                  font-size: 15px;
                  padding: 25px 0 23px;
                  display: table-cell;
                }
                display: none;
                font-family: $ano-bold_regular-font;
                text-transform: uppercase;
                &:first-child {
                  padding-#{$ldirection}: 0;
                }
                &:last-child {
                  padding-#{$rdirection}: 7px;
                  text-align: #{$rdirection};
                  display: none;
                }
                &.loyalty__panel__transactions__list {
                  &__column-spent {
                    @include breakpoint($bp--large-up) {
                      text-align: #{$rdirection};
                    }
                  }
                }
              }
            }
            td,
            th {
              @include breakpoint($bp--large-up) {
                border: none;
              }
            }
            tbody {
              tr {
                @include breakpoint($bp--large-up) {
                  border: none;
                }
                font-family: $helvetica-font;
                border-top: 1px solid $color--gray--lighter;
                border-bottom: 1px solid $color--gray--lighter;
                &.view__more__details {
                  border-top: none;
                  td {
                    border-top: none;
                  }
                  .points__text,
                  .balance__text {
                    font-family: $ano-bold_regular-font;
                  }
                  .rewards__benefits {
                    padding-top: 0;
                    .points__value {
                      font-weight: bold;
                    }
                  }
                }
                &.expando-menu__parent--expanded {
                  border-bottom: none;
                  td {
                    border-bottom: none;
                  }
                  .expando-menu-trigger {
                    background-image: url('/media/export/cms/loyalty/lyl-accordion-arrow-expanded.png');
                    background-repeat: no-repeat;
                    background-position: 22px;
                    height: 36.8px;
                  }
                }
                &:last-child {
                  td {
                    @include breakpoint($bp--large-up) {
                      padding-bottom: 35px;
                    }
                    padding-bottom: 10px;
                  }
                }
                td {
                  @include breakpoint($bp--large-up) {
                    padding: 9px 0;
                    letter-spacing: normal;
                  }
                  letter-spacing: 1px;
                  padding-#{$ldirection}: 0;
                  &.loyalty__panel__transactions__list__column {
                    &-total {
                      @include breakpoint($bp--large-up) {
                        display: none;
                        padding-#{$rdirection}: 2%;
                        text-align: #{$rdirection};
                      }
                    }
                  }
                }
              }
              .expando-sub-menu {
                display: none;
              }
            }
            .loyalty__panel__transactions__list {
              &__column {
                &-activity__points {
                  display: inline;
                }
              }
            }
          }
          &__paginate {
            @include breakpoint($bp--large-up) {
              padding: 3%;
            }
            text-align: center;
            padding: 5%;
            .show__more__btn {
              @include breakpoint($bp--large-up) {
                letter-spacing: normal;
              }
              text-decoration: none;
              display: block;
              font-family: $helvetica-font;
              text-transform: inherit;
              border-bottom: none;
              font-size: 15px;
              letter-spacing: 1px;
            }
          }
        }
        &__column-activity {
          @include breakpoint($bp--large-up) {
            width: 66%;
            padding-#{$ldirection}: 14px;
          }
          display: table-cell;
          width: 64%;
          padding-#{$ldirection}: 0;
          font-size: 13px;
          &.activity-details {
            font-size: 13px;
          }
          &--level {
            &.points__activity {
              @include breakpoint($bp--large-up) {
                padding-#{$ldirection}: 15px;
              }
              padding: 10px 0;
              font-weight: normal;
              text-transform: none;
              font-size: 12px;
            }
          }
          &__transactions {
            @include breakpoint($bp--large-up) {
              display: inline;
            }
          }
        }
        &__column-date {
          @include breakpoint($bp--large-up) {
            padding-#{$ldirection}: 0;
            width: 15%;
          }
          width: 24%;
        }
        &__column-spent {
          &.expando-menu-trigger {
            @include breakpoint($bp--large-up) {
              background-image: none;
            }
            border: none;
            display: block;
            text-align: center;
            background-image: url('/media/export/cms/loyalty/lyl-accordion-arrow-collapsed.png');
            background-repeat: no-repeat;
            background-position: 22px;
            height: 36.8px;
          }
          &__points-spent {
            @include breakpoint($bp--large-up) {
              display: block;
              text-align: #{$rdirection};
            }
            display: none;
          }
        }
        &__column-points {
          @include breakpoint($bp--large-up) {
            width: 12%;
            text-align: #{$ldirection};
          }
        }
        &__column-total {
          @include breakpoint($bp--large-up) {
            text-align: center;
            padding-#{$ldirection}: 0;
          }
        }
      }
    }
  }
}
