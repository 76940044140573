@if $loyalty-redesign == true {
  .mac-lover__events__promos {
    @include breakpoint($bp--large-up) {
      width: 100%;
      margin: 0 auto;
      padding: 6%;
    }
    padding: 15% 6% 18%;
    &--description {
      display: none;
    }
    &--header {
      @include breakpoint($bp--large-up) {
        line-height: 0.5;
      }
      line-height: 0.6;
      margin-bottom: 15px;
      &-link {
        @include breakpoint($bp--large-up) {
          font-size: 46px;
        }
        line-height: 0.6;
        border-bottom: none;
        font-family: $ano-bold_regular-font;
        font-size: 35px;
        text-align: center;
        &:hover {
          color: $color--black;
        }
      }
    }
    &--subheader {
      @include breakpoint($bp--large-up) {
        margin-bottom: 40px;
        line-height: 1.8;
        width: 53%;
      }
      line-height: 1.5;
      font-family: $roboto-mono_regular-font;
      font-size: 13px;
    }
  }
  .mac-select__marketing-page__top {
    @include breakpoint($bp--large-up) {
      padding: 0 110px 0 75px;
    }
    &-col1 {
      @include breakpoint($bp--large-up) {
        float: #{$ldirection};
        width: 60%;
        padding: 0;
      }
      padding: 0 6%;
      .header__title {
        @include breakpoint($bp--large-up) {
          font-size: 53px;
          margin-bottom: 0;
        }
        font-family: $ano-bold_regular-font;
        font-size: 42px;
        line-height: 0.9;
      }
      .header__intro {
        @include breakpoint($bp--large-up) {
          line-height: 1.8;
          font-size: 13px;
        }
        line-height: 1.5;
        font-family: $helvetica-font;
        font-size: 14px;
      }
    }
    &-col2 {
      @include breakpoint($bp--large-up) {
        float: #{$rdirection};
        width: 40%;
        text-align: #{$rdirection};
        margin-top: 16px;
        padding-#{$ldirection}: 0;
      }
      padding-#{$ldirection}: 6%;
    }
    .join__now__btn,
    .sign__now__btn,
    .benefits__btn {
      @include breakpoint($bp--large-up) {
        margin-bottom: 15px;
        width: 38%;
        height: 45px;
      }
      display: inline-block;
      width: 50%;
      line-height: 3.2;
      height: 48px;
      font-family: $ano-black_regular-font;
      font-size: 15px;
      margin-bottom: 20px;
      &:hover {
        text-decoration: none;
      }
    }
    .sign__now__btn {
      @include breakpoint($bp--medium-portrait) {
        width: 42%;
      }
    }
    .join__now__btn {
      @include breakpoint($bp--large-up) {
        margin-top: 0;
      }
      margin-top: 30px;
    }
    .benefits__container {
      text-align: center;
      clear: both;
      display: none;
      .benefits__btn {
        @include breakpoint($bp--large-up) {
          width: 200px;
        }
      }
    }
  }
  .is-eligible {
    .mac-select__marketing-page__top {
      &-col1,
      &-col2 {
        display: none;
      }
      .benefits__container {
        display: block;
      }
    }
  }
  .marketing-benefits {
    &__banner {
      @include breakpoint($bp--large-up) {
        padding: 60px 0 50px;
        position: relative;
      }
      &__header {
        font-family: $ano-bold_regular-font;
        font-size: 35px;
        line-height: 0.85;
        margin-bottom: 10px;
        @include breakpoint($bp--large-up) {
          font-size: 50px;
          line-height: 1;
          margin-bottom: 0;
        }
      }
      &__header__text {
        font-family: $helvetica-font;
        font-size: 10px;
        letter-spacing: 8px;
        margin-bottom: 25px;
        @include breakpoint($bp--large-up) {
          margin-bottom: 30px;
          font-size: 12px;
        }
      }
      .banner-image {
        width: 100%;
        margin: 0 auto;
      }
      &__wrapper {
        background: $color--grey--rewards;
        padding: 30px;
        color: $color--white;
        @include breakpoint($bp--large-up) {
          padding: 50px 90px 30px;
          background: $color--transparent;
          position: absolute;
          top: 18%;
        }
      }
      &__text {
        font-family: $helvetica-font;
        font-size: 10px;
        margin-bottom: 0;
        letter-spacing: 1px;
        line-height: 1.7;
        @include breakpoint($bp--large-up) {
          font-size: 13px;
          line-height: 1.5;
          margin-bottom: 30px;
          letter-spacing: 0;
        }
      }
      &__join {
        text-align: #{$ldirection};
        margin-top: 20px;
        &__button {
          &.button {
            width: 180px;
            display: inline-block;
            background: $color--white;
            color: $color--black;
            font-family: $ano-black_regular-font;
            font-size: 16px;
            text-align: center;
            height: 45px;
            line-height: 2.8;
            &:hover,
            &:focus,
            &:visited {
              color: $color--black;
            }
          }
        }
      }
    }
  }
  .mac-lover__marketing-page__content {
    @include breakpoint($bp--large-up) {
      margin-top: 15px;
    }
  }
  .marketing__redeem__rewards {
    .marketing-benefits {
      &__banner {
        @include breakpoint($bp--large-up) {
          padding: 0;
        }
      }
    }
  }
  .loyalty__panel__redeem-rewards {
    .marketing-benefits__banner {
      @include breakpoint($bp--large-up) {
        padding-top: 0;
      }
      &__join {
        .button {
          display: none;
        }
      }
    }
  }
}
