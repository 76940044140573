@if $loyalty-redesign == true {
  .loyalty__panel__offers__sku {
    @include breakpoint($bp--large-up) {
      padding: 0 5px;
    }
    padding-top: 30px;
    &__title {
      margin: 40px 0 10px;
      font-size: 20px;
      line-height: 1;
      text-align: center;
      letter-spacing: normal;
    }
    &__cta {
      @include breakpoint($bp--large-up) {
        margin-#{$ldirection}: 14px;
      }
      a {
        color: $color--full-white;
        &.loyalty__panel__offers__sku__loader__button {
          color: $color--full-white;
          &.button--disabled {
            color: $color--black;
          }
        }
        &.loyalty__panel__product__button {
          color: $color--full-white;
        }
      }
      &__container {
        &.oos,
        &.sold-out {
          .loyalty__panel__offers__sku__loader__button {
            cursor: not-allowed;
            background-color: $color--gray--tier5;
            pointer-events: none;
            color: $color--black;
          }
        }
      }
    }
    &__oos__container,
    &__sold-out__container {
      text-transform: uppercase;
      color: $color--red--dark;
      margin-bottom: 10px;
      font-size: 15px;
    }
    &__loader {
      display: none;
      img {
        margin: 0 auto;
      }
      &__button {
        @include breakpoint($bp--large-up) {
          font-size: 15px;
          padding: 0 50px;
        }
        text-align: center;
        font-size: 18px;
        font-family: $ano-black_regular-font;
        padding: 10px 70px;
        display: inline-block;
        margin: 0;
        color: $color--full-white;
        background: $color--black;
        cursor: pointer;
        &.not_meet {
          pointer-events: none;
        }
        &.button--disabled {
          background: $color--gray--lightest;
          color: $color--black;
          cursor: not-allowed;
        }
      }
    }
    &__list-container {
      .slick-track {
        margin: 0 auto;
      }
      .tabbed-rewards-block {
        &__content {
          @include breakpoint($bp--large-up) {
            padding: 0 20px;
          }
          &-item {
            height: 0;
            opacity: 0;
            overflow: hidden;
            transition: opacity 1s ease-in-out;
            width: 100%;
            &.current {
              height: auto;
              overflow: visible;
              opacity: 1;
            }
            .product-brief-carousel {
              border: none;
              &.grid--mpp__carousel {
                .carousel-controls {
                  @include breakpoint($bp--large-up) {
                    top: 37%;
                  }
                  .slick-next,
                  .slick-prev {
                    @include breakpoint($bp--large-up) {
                      z-index: 10;
                    }
                    top: 0;
                    &:before {
                      @include breakpoint($bp--large-up) {
                        height: 18px;
                        width: 18px;
                      }
                      content: '';
                      display: block;
                      height: 14px;
                      width: 14px;
                      background-repeat: no-repeat;
                    }
                  }
                  .slick-prev {
                    @include breakpoint($bp--large-up) {
                      #{$ldirection}: -5px;
                    }
                    margin-#{$ldirection}: 0;
                    &:before {
                      @include breakpoint($bp--large-up) {
                        background-image: url('/media/export/cms/loyalty/pc-rewards-left-arrow.png');
                      }
                      background-image: url('/media/export/cms/loyalty/mobile-rewards-left-arrow.png');
                    }
                  }
                  .slick-next {
                    margin-#{$rdirection}: 0;
                    #{$rdirection}: -5px;
                    &:before {
                      @include breakpoint($bp--large-up) {
                        background-image: url('/media/export/cms/loyalty/pc-rewards-right-arrow.png');
                      }
                      background-image: url('/media/export/cms/loyalty/mobile-rewards-right-arrow.png');
                    }
                  }
                }
              }
            }
            .product-grid--carousel {
              &.grid--mpp__items {
                border: none;
                .slick-list {
                  @include breakpoint($bp--large-up) {
                    width: 100%;
                  }
                  width: 90%;
                  margin: 0 auto;
                  padding: 0;
                }
                .grid--mpp__item {
                  @include breakpoint($bp--large-up) {
                    padding: 0 30px;
                  }
                  border: none;
                  padding: 0;
                  margin: 0;
                  text-align: center;
                  float: #{$ldirection};
                  position: relative;
                  @include breakpoint($bp--medium-portrait) {
                    max-width: 100%;
                  }
                  .offer_not_meet {
                    &.disable_product {
                      &.button--disabled {
                        display: block;
                      }
                    }
                  }
                }
              }
            }
          }
          &-bottom {
            &-description {
              text-align: center;
              margin-top: 0;
            }
          }
        }
        &__tabs {
          @include breakpoint($bp--large-up) {
            padding: 0 80px;
          }
          padding: 0 13px;
          text-align: center;
          font-size: 0;
        }
        &__tab {
          @include breakpoint($bp--large-up) {
            font-size: 16px;
            width: 12%;
            padding: 10px 8px 16px;
          }
          opacity: 0.5;
          font-family: $ano-black_regular-font;
          padding: 10px 8px 7px;
          border-bottom: 1px solid $color--gray--lighter;
          color: $color--black;
          cursor: pointer;
          display: inline-block;
          letter-spacing: 0;
          font-size: 2.5vw;
          line-height: 21px;
          text-decoration: none;
          text-transform: uppercase;
          width: 25%;
          margin-bottom: 15px;
          text-align: center;
          &.current {
            border-bottom: 1.2px solid $color--black;
            opacity: 1;
            font-family: $ano-black_regular-font;
          }
        }
        .grid--mpp__carousel {
          animation-duration: 1.5s;
        }
        .product-brief-carousel {
          border-top: none;
        }
      }
    }
    &__select {
      text-align: center;
      line-height: 1.25;
    }
    &__benefits {
      text-align: center;
      margin-top: 5px;
      &__link {
        text-decoration: underline;
        font-size: 18px;
        text-transform: capitalize;
      }
    }
    &__product {
      padding: 5px;
      text-align: center;
      &__data__wrapper {
        @include breakpoint($bp--large-up) {
          margin: 14px;
        }
        margin: 14px 0;
        min-height: 60px;
      }
      &__image {
        &-container {
          display: inline-block;
          text-align: center;
          min-height: 320px;
          @include breakpoint($bp--large-up) {
            min-height: 300px;
            img {
              max-height: 300px;
              margin: 0 auto;
            }
          }
          @include breakpoint($width-xxlarge) {
            min-height: 400px;
            img {
              max-height: 400px;
            }
          }
          &.rewards-not-eligible {
            opacity: 0.2;
          }
        }
      }
      &__header {
        font-size: 18px;
        line-height: 1;
        margin-bottom: 3px;
        letter-spacing: normal;
        text-transform: uppercase;
        font-family: $ano-bold_regular-font;
        font-weight: bold;
        min-height: 54px;
      }
      &__sub-header {
        font-size: 16px;
        margin-bottom: 10px;
        line-height: 1.15;
        font-family: $helvetica-font;
        min-height: 16px;
        @include breakpoint($width-xxlarge) {
          font-size: 14px;
        }
      }
      &__offer-code {
        font-family: $ano-bold_regular-font;
        font-size: 16px;
        margin-bottom: 25px;
        min-height: 15px;
        @include breakpoint($width-xxlarge) {
          margin-bottom: 0;
          font-size: 14px;
        }
      }
    }
  }
  #loyalty__panel__offers {
    @include breakpoint($bp--large-up) {
      padding-bottom: 60px;
    }
    border-bottom: none;
    padding: 0;
    margin: 0 0 35px 0;
    .loyalty__panel__offers {
      @include swap_direction(padding, 40px 0 24px);
      border-bottom: 1px solid $color--gray--lightest;
      &__list-container {
        @include breakpoint($bp--large-up) {
          margin-top: -8.5%;
          padding: 0 2.5%;
        }
        .slick-track {
          margin: 0 auto;
        }
        margin-top: -23%;
        .slick-next,
        .slick-prev {
          color: $color--black;
          &:before {
            @include breakpoint($bp--large-up) {
              height: 18px;
              width: 18px;
              z-index: 10;
            }
            content: '';
            display: block;
            height: 14px;
            width: 14px;
            background-repeat: no-repeat;
            color: $color--black;
          }
        }
        .slick-prev {
          @include breakpoint($bp--large-up) {
            #{$ldirection}: -30px;
          }
          margin-#{$ldirection}: 0;
          #{$ldirection}: 2px;
          &:before {
            @include breakpoint($bp--large-up) {
              background-image: url('/media/export/cms/loyalty/pc-rewards-left-arrow.png');
            }
            background-image: url('/media/export/cms/loyalty/mobile-rewards-left-arrow.png');
          }
        }
        .slick-next {
          @include breakpoint($bp--large-up) {
            #{$rdirection}: -30px;
          }
          margin-#{$rdirection}: 0;
          #{$rdirection}: -5px;
          &:before {
            @include breakpoint($bp--large-up) {
              background-image: url('/media/export/cms/loyalty/pc-rewards-right-arrow.png');
            }
            background-image: url('/media/export/cms/loyalty/mobile-rewards-right-arrow.png');
          }
        }
        .slick-slide {
          text-align: center;
          img {
            display: inline-block;
          }
        }
      }
      &__product__header {
        padding-bottom: 15px;
        min-height: 60px;
        font-family: $ano-bold_regular-font;
        font-weight: bold;
        font-size: 18px;
      }
      &__product {
        &__image-container,
        &__image {
          min-height: 410px;
          @include breakpoint($bp--large-up) {
            min-height: 340px;
          }
          @include breakpoint($width-xxlarge) {
            min-height: 442px;
          }
        }
        &__image {
          margin: 0 auto;
        }
      }
      &__title {
        font-size: 21px;
        line-height: 1;
        text-align: center;
        margin-bottom: 10px;
        letter-spacing: normal;
      }
      &__subtitle {
        line-height: 1.5;
        text-align: center;
        font-size: 16px;
        margin-bottom: 30px;
      }
      &__select {
        text-align: center;
        line-height: 1.5;
      }
      &__benefits {
        text-align: center;
        margin-top: 5px;
        &__link {
          text-decoration: underline;
          font-size: 16px;
          text-transform: capitalize;
        }
      }
      &__loader {
        display: none;
        &__button {
          @include breakpoint($bp--large-up) {
            font-size: 15px;
            padding: 0 50px;
          }
          text-align: center;
          font-size: 18px;
          font-family: $ano-black_regular-font;
          padding: 10px 70px;
          display: inline-block;
          margin: 0;
          color: $color--full-white;
          background: $color--black;
          cursor: pointer;
          &.not_meet {
            pointer-events: none;
          }
          &.button--disabled {
            background: $color--gray--lightest;
            color: $color--black;
            cursor: not-allowed;
          }
        }
      }
      &__offer {
        @include swap_direction(padding, 12px);
        text-align: center;
        &-image-container {
          height: 320px;
          display: inline-block;
          text-align: center;
          @include breakpoint($bp--large-up) {
            height: auto;
          }
        }
        &-header {
          @include breakpoint($bp--large-up) {
            margin-#{$ldirection}: 20px;
          }
          text-align: #{$ldirection};
          font-size: 14px;
          line-height: 1;
          margin-bottom: 10px;
          padding-top: 16px;
          letter-spacing: normal;
          text-transform: uppercase;
          font-family: $ano-bold_regular-font;
        }
        &-description {
          @include breakpoint($bp--large-up) {
            margin-#{$ldirection}: 20px;
          }
          text-align: #{$ldirection};
          font-size: 14px;
          margin-bottom: 10px;
          line-height: 1.15;
          font-family: $helvetica-font;
        }
        &-valid {
          margin-bottom: 10px;
          text-transform: uppercase;
          font-size: 13px;
        }
      }
      &__button {
        &-container {
          text-align: #{$ldirection};
          .button {
            &--disabled {
              background: $color--gray--lightest;
            }
          }
        }
      }
      &__button,
      &__selected,
      &__remove,
      &__redeemed {
        @include breakpoint($bp--large-up) {
          font-size: 15px;
          padding: 0 50px;
          margin-#{$ldirection}: 20px;
        }
        text-align: center;
        font-size: 18px;
        font-family: $ano-black_regular-font;
        padding: 10px 70px;
        display: inline-block;
        margin: 0;
        color: $color--full-white;
        background: $color--black;
        cursor: pointer;
        &.not_meet {
          pointer-events: none;
        }
      }
      &__oos__container,
      &__sold-out__container {
        text-transform: uppercase;
        color: $color--red--dark;
        margin-bottom: 10px;
      }
      &__cta__container {
        &.oos,
        &.sold-out {
          .loyalty__panel__offers__shade__button,
          .loyalty__panel__offers__button {
            cursor: not-allowed;
            background-color: $color--gray--tier5;
            pointer-events: none;
          }
        }
      }
    }
  }
  .loyalty__your__birthday {
    position: relative;
    &__img {
      @include breakpoint($bp--large-up) {
        background-image: url('/media/export/cms/loyalty/birthday-header-pc-bg.png');
        height: 550px;
        margin: 0 auto;
        width: 100%;
      }
      height: 640px;
      background-image: url('/media/export/cms/loyalty/birthday-banner-mobie.jpg');
      background-repeat: no-repeat;
      background-size: 100%;
    }
    &__description {
      @include breakpoint($bp--large-up) {
        #{$rdirection}: 0%;
        #{$ldirection}: 59%;
        top: 14%;
        padding: 0;
      }
      @include breakpoint($bp--medium-portrait) {
        top: 20%;
        text-align: center;
      }
      @include breakpoint($bp--medium-landscape) {
        top: 4%;
      }
      position: absolute;
      top: 42%;
      padding: 0 6%;
      color: $color--full-white;
      .birthday {
        &__user {
          @include breakpoint($bp--large-up) {
            font-size: 12px;
            line-height: 0.9;
          }
          text-transform: uppercase;
          font-size: 11px;
          font-family: $helvetica-font;
          letter-spacing: 8px;
        }
        &__title {
          @include breakpoint($bp--large-up) {
            padding: 25px 0 10px;
            font-size: 53px;
          }
          padding-top: 20px;
          font-size: 43px;
          font-family: $ano-bold_regular-font;
          line-height: 0.9;
        }
        &__subtitle,
        &__offer__time,
        &__offer__palette__text {
          @include breakpoint($bp--large-up) {
            line-height: 2;
            display: block;
          }
          font-family: $roboto-mono_regular-font;
          font-size: 13px;
          display: inline;
        }
        &__offer__time,
        &__offer__palette__text {
          @include breakpoint($bp--large-up) {
            display: block;
            width: 80%;
          }
          .gift_status {
            @include breakpoint($bp--large-up) {
              display: block;
            }
          }
        }
      }
      .add__to__cart,
      .remove__from__cart {
        &__container {
          @include breakpoint($bp--large-up) {
            padding-top: 28px;
          }
          padding-top: 18px;
        }
        &-btn {
          padding: 13px 50px;
          font-size: 15px;
          font-family: $ano-black_regular-font;
          color: $color--black;
          background: $color--full-white;
          cursor: pointer;
          text-decoration: none;
          &:hover,
          &:visited,
          &:focus {
            text-decoration: none;
          }
          &.button--disabled {
            line-height: 1.25;
            display: inline;
            cursor: not-allowed;
            background-color: $color--gray--tier5;
          }
        }
      }
    }
  }
}
