#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-review {
        .pr-rd-footer {
          .pr-helpful-yes {
            .pr-helpful-count {
              &:before {
                content: 'Nuttig(';
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              &:before {
                content: 'Niet Nuttig(';
              }
            }
          }
          .pr-rd-helpful-text {
            width: 100%;
            margin-bottom: 20px;
          }
          .pr-helpful-btn {
            float: left;
          }
          .pr-rd-flag-review-container {
            float: left;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
/* FR region */
:lang(fr-e-BE) {
  #pr-reviewdisplay {
    .p-w-r {
      .pr-review-display {
        .pr-review {
          .pr-rd-footer {
            .pr-helpful-yes {
              .pr-helpful-count {
                &:before {
                  content: 'Utile(';
                }
              }
            }
            .pr-helpful-no {
              .pr-helpful-count {
                &:before {
                  content: 'Inutile(';
                }
              }
            }
            .pr-rd-helpful-text {
              width: 100%;
              margin-bottom: 20px;
            }
            .pr-helpful-btn {
              float: left;
            }
            .pr-rd-flag-review-container {
              float: left;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
}
