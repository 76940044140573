@if $loyalty-redesign == true {
  .checkout-optimization {
    #confirmation-page {
      .picker {
        &.picker-checkbox {
          label {
            &.social-login__opt-in-label {
              @include breakpoint($bp--large-up) {
                max-width: 90%;
              }
              @include breakpoint($bp--medium-portrait) {
                max-width: 90%;
              }
              max-width: 80%;
              display: inline-block;
            }
            &.picker-label {
              padding-top: 2px;
            }
          }
          &.checked {
            .picker-flag {
              background: none;
              opacity: 1;
              &:before {
                margin: 0;
              }
            }
          }
          .picker-handle {
            height: 20px;
            width: 20px;
            display: inline-block;
            border: 1px solid $color--gray--dusty;
            .picker-flag {
              &:before {
                margin: 0;
              }
            }
          }
        }
      }
    }
    .checkout__content {
      .panel.rewards__panel {
        margin: 0;
        border-top: none;
        background: $color--gray--thick;
        padding-top: 0;
        .expando-block__content {
          padding-top: 10px;
        }
        header {
          padding: 21px 25px;
          margin: 0;
          background-color: $color--gray--thick;
          @include breakpoint($bp--large-up) {
            text-align: center;
          }
          h2 {
            margin: 0;
            font-family: $ano-bold_regular-font;
            font-size: 18px;
            position: relative;
            font-weight: bold;
            i {
              position: absolute;
              top: 30%;
              #{$rdirection}: 10px;
              @include breakpoint($bp--large-up) {
                top: 0;
                &:before {
                  font-size: 25px;
                }
              }
            }
            .header-text {
              display: inline-block;
              width: 50%;
              @include breakpoint($bp--large-up) {
                display: inline;
                font-family: $ano-bold_regular-font;
              }
              @include breakpoint($bp--medium-portrait) {
                display: block;
                text-align: center;
                width: 100%;
              }
            }
            .header-points {
              display: none;
              @include breakpoint($bp--large-up) {
                font-size: 16px;
                font-family: $helvetica-font;
                display: inline;
                padding-#{$ldirection}: 10px;
              }
            }
            @include breakpoint($bp--large-up) {
              font-size: 16px;
            }
          }
        }
      }
    }
    .checkout {
      .order-summary-panel {
        .loyalty__points__description {
          @include breakpoint($bp--large-up) {
            margin-bottom: 30px;
          }
          padding: 0 10%;
          text-align: center;
          margin-bottom: 40px;
          &.enrolment__text {
            @include breakpoint($bp--large-up) {
              padding: 0 22%;
            }
            padding: 0 26%;
          }
          .highlighted__text {
            font-weight: bold;
          }
        }
      }
      .loyalty-birthday-panel {
        .checkout__offer-code__wallet {
          .product__sku-details {
            .product__header {
              min-height: 75px;
            }
          }
        }
        .slick-next,
        .slick-prev {
          @include breakpoint($bp--large-up) {
            z-index: 10;
          }
          &:before {
            @include breakpoint($bp--large-up) {
              height: 18px;
              width: 18px;
            }
            content: '';
            display: block;
            height: 14px;
            width: 14px;
            background-repeat: no-repeat;
          }
        }
        .slick-prev {
          margin-#{$ldirection}: 0;
          &:before {
            @include breakpoint($bp--large-up) {
              background-image: url('/media/export/cms/loyalty/pc-rewards-left-arrow.png');
            }
            background-image: url('/media/export/cms/loyalty/mobile-rewards-left-arrow.png');
          }
        }
        .slick-next {
          margin-#{$rdirection}: 0;
          #{$rdirection}: -2px;
          &:before {
            @include breakpoint($bp--large-up) {
              background-image: url('/media/export/cms/loyalty/pc-rewards-right-arrow.png');
            }
            background-image: url('/media/export/cms/loyalty/mobile-rewards-right-arrow.png');
          }
        }
        .loyalty-birthday-panel__footer {
          .btn {
            &.btn--full {
              font-size: 17px;
              line-height: 3.2;
              &:hover {
                text-decoration: none;
              }
              &.button--disabled {
                opacity: 0.5;
                cursor: not-allowed;
                height: 60px;
              }
            }
          }
        }
        .button__container {
          text-align: center;
          .palette__btn {
            line-height: 2.7;
            width: auto;
            font-size: 15px;
            height: 38px;
            font-family: $ano-black_regular-font;
            border-width: 0;
            display: inline-block;
            background-color: $color--dark--black;
            color: $color--full-white;
            padding: 0 20px;
            &:hover,
            &:focus,
            &:visited {
              text-decoration: none;
            }
            &--disabled {
              opacity: 0.5;
              cursor: not-allowed;
            }
          }
        }
        .birthday__image {
          &__container,
          &__description {
            text-align: center;
          }
          &__description {
            padding: 10px 0;
          }
        }
      }
      .rewards__panel {
        @include breakpoint($bp--large-up) {
          background-color: $color--gray--thick;
          .slick-track {
            margin: 0 auto;
          }
        }
        &-content {
          .slick-list {
            @include breakpoint($bp--large-up) {
              padding: 20px;
            }
          }
        }
        &.expando-block--expanded {
          .expando-block__content {
            border: none;
            @include breakpoint($bp--large-up) {
              padding: 10px 50px;
              margin-bottom: 0;
            }
            .slick-prev {
              position: absolute;
              #{$ldirection}: 3px;
              @include breakpoint($bp--large-up) {
                #{$ldirection}: 10px;
              }
              &:before {
                display: block;
                font-size: 15px;
                @include breakpoint($bp--large-up) {
                  font-size: 22px;
                }
              }
            }
            .slick-next {
              @include breakpoint($bp--large-up) {
                #{$rdirection}: 10px;
                &:before {
                  font-size: 22px;
                }
              }
            }
            .slick-list {
              padding: 0;
              margin: 0 auto;
            }
            .content-header {
              @include breakpoint($bp--large-up) {
                padding: 0;
              }
              padding: 0 20px;
              display: block;
              text-align: center;
              font-size: 16px;
              font-family: $helvetica-font;
            }
          }
        }
        .offer-code-wallet__item {
          padding: 0 17px;
          text-align: center;
          @include breakpoint($bp--large-up) {
            padding: 0 5px;
          }
        }
        .offer-code-wallet__code {
          &__oos,
          &__sold-out {
            text-transform: uppercase;
            color: $color--red--dark;
            margin-bottom: 10px;
          }
          &__loader {
            text-align: center;
            display: inline-block;
          }
          &__image-container {
            display: inline-block;
            text-align: center;
            height: 160px;
            margin-bottom: 10px;
            img {
              height: 100%;
              margin: 0 auto;
            }
          }
          &-info--applied {
            @include breakpoint($bp--large-up) {
              width: 100%;
            }
          }
          &__description {
            .product_shade {
              font-family: $ano-bold_regular-font;
              @include breakpoint($bp--large-up) {
                font-size: 15px;
                margin-bottom: 10px;
              }
            }
            &-title {
              font-family: $ano-bold_regular-font;
              font-size: 15px;
              text-transform: uppercase;
              margin-bottom: 10px;
              @include breakpoint($bp--large-up) {
                font-size: 16px;
                font-family: $ano-bold_regular-font;
                margin: 10px 0 10px;
                min-height: 64px;
                line-height: 1;
              }
              @include breakpoint($bp--medium-landscape) {
                min-height: 100px;
              }
            }
            &-expiration {
              font-family: $ano-bold_regular-font;
              font-size: 12px;
            }
            &-description {
              font-family: $ano-bold_regular-font;
              @include breakpoint($bp--large-up) {
                font-size: 15px;
                margin-bottom: 10px;
              }
            }
            &-points {
              font-family: $ano-bold_regular-font;
              font-size: 15px;
              text-transform: uppercase;
              margin: 10px 0 20px;
              @include breakpoint($bp--large-up) {
                font-size: 16px;
                margin-bottom: 20px;
                font-weight: bold;
                min-height: 20px;
              }
            }
          }
        }
        .offer-code-wallet__button {
          &--remove {
            margin-top: 5px;
            font-family: $ano-bold_regular-font;
            font-size: 15px;
            line-height: 1.18;
            height: inherit;
            padding: 10px 20px;
            text-decoration: none;
            background: $color--dark--black;
            color: $color--full-white;
          }
          &--apply {
            padding: 10px 20px;
            font-family: $ano-bold_regular-font;
            font-size: 15px;
            line-height: 1;
            height: inherit;
            &:hover,
            &:focus,
            &:visited {
              text-decoration: none;
              border-bottom: 1px solid $color--gray--light;
            }
            &.disabled {
              cursor: not-allowed;
              background-color: $color--disabled;
              color: $color--black;
              pointer-events: none;
            }
          }
        }
      }
    }
  }
}
