@if $loyalty-redesign == true {
  .select-marketing-page,
  #loyalty__page__about {
    .loyalty__romance__with__rewards {
      @include breakpoint($bp--large-up) {
        margin: 0;
        max-width: inherit;
        padding: 0 6% 7%;
      }
      padding-bottom: 64px;
      fieldset {
        @include breakpoint($bp--large-up) {
          display: block;
        }
        display: contents;
      }
      .loyalty-thanks__fieldset {
        .join__title {
          @include breakpoint($bp--large-up) {
            font-size: 52px;
            margin: 5px 0 0;
          }
          margin: 5px 0 3px;
          font-family: $ano-bold_regular-font;
          font-size: 42px;
          letter-spacing: -0.02em;
        }
        .field-container {
          @include breakpoint($bp--large-up) {
            max-width: inherit;
          }
        }
      }
      .signin__already__member__error {
        @include breakpoint($bp--large-up) {
          font-size: 12px;
          line-height: 1.7;
        }
        font-family: $roboto-mono_regular-font;
        font-size: 15px;
        .registration__link {
          @include breakpoint($bp--large-up) {
            display: block;
          }
        }
      }
      .loyalty-user__item {
        &.loyalty__create__redeem__acct {
          @include breakpoint($bp--large-up) {
            font-size: 12px;
            line-height: 1.7;
          }
          margin: 15px 0;
          font-family: $roboto-mono_regular-font;
          font-size: 15px;
          .create__account {
            @include breakpoint($bp--large-up) {
              display: block;
            }
          }
        }
        &.mac__lover__id__fields {
          @include breakpoint($bp--large-up) {
            font-size: 12px;
            line-height: 1.7;
          }
          font-family: $roboto-mono_regular-font;
          font-size: 15px;
          .field {
            @include breakpoint($bp--large-up) {
              font-size: 12px;
              line-height: 1.7;
            }
            font-family: $roboto-mono_regular-font;
            font-size: 15px;
          }
        }
        .picker-checkbox {
          @include breakpoint($bp--large-up) {
            margin-top: 18px;
          }
          margin-top: 0;
          .picker {
            &-handle {
              border-radius: 0;
            }
            &-label {
              .label-content {
                color: $color--gray--border;
                line-height: 1;
                letter-spacing: 0.8px;
                font-family: $roboto-mono_regular-font;
                font-size: 12px;
              }
            }
          }
        }
      }
      .loyalty__rewards__title {
        @include breakpoint($bp--large-up) {
          font-size: 46px;
          margin: 5px 0 0;
        }
        margin: 5px 0 3px;
        font-family: $ano-bold_regular-font;
        font-size: 35px;
        letter-spacing: -0.02em;
        line-height: 0.9;
      }
      .email__details {
        @include breakpoint($bp--large-up) {
          font-size: 12px;
          line-height: 1.7;
        }
        padding-top: 23px;
        font-family: $roboto-mono_regular-font;
        font-size: 15px;
        &.email__register {
          padding-top: 0;
        }
      }
      .benefits__text {
        @include breakpoint($bp--large-up) {
          font-size: 12px;
          line-height: 1.7;
        }
        font-family: $roboto-mono_regular-font;
        font-size: 15px;
        .level__style {
          @include breakpoint($bp--large-up) {
            display: block;
          }
        }
      }
      .loyalty__enrollment__fields {
        &__input__text {
          @include breakpoint($bp--large-up) {
            width: 49%;
          }
          input {
            padding: 16px 21px 16px 12px;
            line-height: 1;
            letter-spacing: 0.8px;
            font-family: $roboto-mono_regular-font;
            font-size: 12px;
          }
        }
        &__submit__btn {
          @include breakpoint($bp--large-up) {
            width: 49%;
            margin-top: 5.4px;
          }
          margin-top: 15px;
          height: 50px;
          line-height: 0.8;
          font-family: $ano-bold_regular-font;
          font-size: 18px;
          letter-spacing: -0.02em;
          &.submit-btn {
            @include breakpoint($bp--large-up) {
              width: 48%;
            }
          }
          &:hover {
            text-decoration: none;
          }
        }
      }
      .loyalty-user-details__fieldset {
        .reset__pwd,
        .password-field {
          &__loyalty {
            @include breakpoint($bp--large-up) {
              font-size: 12px;
              line-height: 1.7;
            }
            font-family: $roboto-mono_regular-font;
            font-size: 15px;
          }
        }
        .field-container {
          @include breakpoint($bp--large-up) {
            max-width: inherit;
          }
          .form-item {
            @include breakpoint($bp--large-up) {
              margin-bottom: 20px;
            }
            margin-bottom: 30px;
            &_birthday {
              @include breakpoint($bp--large-up) {
                width: 49%;
              }
              &_label {
                @include breakpoint($bp--large-up) {
                  padding: 0;
                  position: relative;
                  bottom: 5px;
                }
                letter-spacing: 0.8px;
                font-family: $roboto-mono_regular-font;
                .birthday {
                  font-size: 12px;
                }
              }
            }
            .select2-container {
              border: 1px solid $color--black;
              letter-spacing: 0.8px;
              font-family: $roboto-mono_regular-font;
              font-size: 12px;
              .select2-choice {
                height: 30px;
                line-height: 30px;
                border-bottom: none;
                .select2-arrow {
                  b {
                    &:before {
                      @include breakpoint($bp--large-up) {
                        top: 10px;
                      }
                    }
                  }
                }
              }
            }
            .form-item_month_year {
              .form-item {
                &:last-child {
                  @include breakpoint($bp--large-up) {
                    margin-#{$rdirection}: 0;
                  }
                  margin-#{$rdirection}: 2%;
                }
                select {
                  line-height: 50px;
                  height: 50px;
                  letter-spacing: 0.8px;
                  font-family: $roboto-mono_regular-font;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
      .loyalty-user__create {
        &-account {
          &__section,
          &__offer {
            @include breakpoint($bp--large-up) {
              margin-top: 0;
            }
          }
          .email__optin {
            &.radio {
              margin: 0;
              .picker-handle {
                border-radius: 0;
              }
              .picker-label {
                @include breakpoint($bp--large-up) {
                  width: 60%;
                }
                color: $color--gray--border;
                line-height: 1.7;
                letter-spacing: 0.8px;
                font-family: $roboto-mono_regular-font;
                font-size: 12px;
              }
            }
          }
          &__offer {
            color: $color--brick-red;
            font-family: $ano-bold_regular-font;
            font-size: 18px;
            .offer__code {
              @include breakpoint($bp--large-up) {
                display: inline;
              }
              display: block;
            }
            .learn__more__link {
              display: block;
              color: $color--black;
              text-decoration: underline;
              border-bottom: none;
            }
          }
        }
      }
      .terms__conditions__container {
        @include breakpoint($bp--large-up) {
          font-size: 12px;
          line-height: 1.7;
        }
        font-family: $roboto-mono_regular-font;
        font-size: 15px;
        .email__terms {
          @include breakpoint($bp--large-up) {
            display: block;
          }
        }
        .enrolment__terms__conditions {
          @include breakpoint($bp--large-up) {
            display: inline-block;
            width: 70%;
          }
        }
      }
    }
  }
}
