@if $loyalty-redesign == true {
  #loyalty__page__index-bottom {
    visibility: hidden;
  }
  .loyalty__content-bottom,
  .loyalty-select__bottom {
    &__section {
      border-top: none;
      &-header {
        display: none;
      }
    }
    .mac-select__marketing-page {
      .loyalty__content-bottom,
      .loyalty-select__bottom {
        &__section-container {
          background: $color--black;
          padding: 15% 0 5%;
          @include breakpoint($width-large - 1) {
            padding: 10% 0 5%;
          }
          .loyalty__button__container {
            @include breakpoint($width-large - 1) {
              margin-top: 25px;
            }
            text-align: center;
            position: relative;
            z-index: 10;
            .button {
              line-height: inherit;
              height: 58.4px;
              padding: 18px 80px;
              background: $color--white;
              color: $color--black;
              font-family: $ano-black_regular-font;
              font-size: 18px;
              margin-bottom: 32px;
              display: inline-block;
              text-decoration: none;
              border: 0;
              &--disabled {
                background: $color--gray--lightest;
                pointer-events: none;
              }
            }
          }
          &-header {
            @include swap_direction(margin, 10% 10% 2% 10%);
            color: $color--white;
            width: 76%;
            line-height: 1;
            font-size: 41px;
            font-family: $ano-bold_regular-font;
            @include breakpoint($width-large - 1) {
              @include swap_direction(margin, 0 6% 2%);
              font-size: 53px;
            }
          }
          &-shade {
            @include breakpoint($width-large - 1) {
              bottom: 0;
            }
            position: absolute;
            top: inherit;
            bottom: 20%;
            #{$ldirection}: 0;
          }
          &-ribbon {
            position: absolute;
            top: 0;
            #{$rdirection}: 0;
          }
          &-content {
            position: relative;
            z-index: 10;
            margin: 0% 10% 10%;
            @include breakpoint($width-large - 1) {
              margin: 0 6%;
            }
          }
        }
      }
      &__disclaimer {
        z-index: 1;
        display: block;
        position: relative;
        p {
          font-family: $roboto-mono_regular-font;
          font-size: 12px;
          color: $color--white;
          text-align: center;
          padding: 0 8%;
          line-height: 1.8;
          .terms__link {
            color: $color--white;
          }
        }
      }
      &__questions {
        &-copy {
          @include breakpoint($bp--large-up) {
            font-size: 15px;
            margin: 50px 0 0;
          }
          margin: 40px 0;
          color: $color--white;
          text-align: center;
          font-family: $ano-black_regular-font;
          font-size: 20px;
          a {
            color: $color--white;
          }
        }
      }
      &__title {
        width: 37%;
        @include breakpoint($bp--medium-portrait) {
          width: 70%;
        }
      }
      &__romance-header {
        width: 40%;
        @include breakpoint($bp--medium-portrait) {
          width: 60%;
        }
      }
      &__header-col2 {
        .mac-select__marketing-page {
          &__signin,
          &__join__hyperlink {
            font-size: 17px;
            @include breakpoint($bp--medium-up) {
              padding: 0;
            }
            @include breakpoint($bp--large-up) {
              padding: 0 55px;
            }
          }
        }
      }
      .macselect-benefits__container {
        width: 90%;
        margin: 0 auto;
        @include breakpoint($width-large - 1) {
          @include swap_direction(padding, 0 0 0 20px);
          width: 100%;
          background: $color--white;
        }
        &__header {
          background: $color--black;
          color: $color--white;
          padding-bottom: 20px;
          h1 {
            font-family: $ano-bold_regular-font;
            font-size: 45px;
            margin-bottom: 0;
          }
        }
        &__content {
          color: $color--white;
          display: flex;
          .levels {
            height: 250px;
          }
          .arrow {
            width: 0;
            height: 0;
          }
          &__left {
            flex: 0;
            .levels {
              border: 1px solid $color--white;
              border-bottom: 0;
              border-#{$rdirection}: 0;
              width: 73px;
            }
            .macselect-benefits__container__content {
              &__level1 {
                height: 424px;
                background-color: $color--pink-tier1;
              }
              &__level2 {
                height: 636px;
                border-top: 0;
                background-color: $color--red-tier2;
                .arrow {
                  border-#{$ldirection}: 35px solid $color--red-tier2;
                  border-#{$rdirection}: 35px solid $color--red-tier2;
                  border-top: 20px solid $color--pink-tier1;
                }
              }
              &__level3 {
                height: 848px;
                background-color: $color--gray-tier3;
                border-top: none;
                border-bottom: 1px solid $color--white;
                .arrow {
                  border-#{$ldirection}: 35px solid $color--gray-tier3;
                  border-#{$rdirection}: 35px solid $color--gray-tier3;
                  border-top: 20px solid $color--red-tier2;
                }
              }
              &__level4 {
                border-top: 0;
                background-color: $color--gray-tier3;
                border-bottom: 1px solid $color--white;
                .arrow {
                  background: $color--red-tier2;
                  border-#{$ldirection}: 35px solid $color--gray-tier3;
                  border-#{$rdirection}: 35px solid $color--gray-tier3;
                  border-top: 20px solid $color--red-tier2;
                }
              }
              &__level1,
              &__level2,
              &__level3 {
                position: relative;
                display: table;
                &-level {
                  text-align: center;
                }
                .lover__container {
                  position: absolute;
                  top: 50%;
                  #{$ldirection}: 50%;
                  transform: translateX(-50%) translateY(-50%) rotate(-90deg);
                  display: table;
                }
              }
            }
          }
          &__right {
            width: 100%;
            .macselect-benefits__container__content {
              &__level1,
              &__level2,
              &__level3,
              &__level4 {
                display: table-row;
                ul {
                  li {
                    height: 106px;
                    font-family: $ano-bold_regular-font;
                    display: block;
                    padding: 0 4%;
                    border: 1px solid $color--white;
                    &:before {
                      content: '';
                      display: inline-block;
                      vertical-align: middle;
                      height: 100%;
                    }
                    .format__item {
                      display: inline-block;
                    }
                    span {
                      display: inline-block;
                      vertical-align: middle;
                      &.benefits__description {
                        font-size: 11px;
                        font-family: $roboto-mono_regular-font;
                        display: inline-block;
                      }
                    }
                  }
                }
              }
              &__level4 {
                background-color: $color--gray-tier3;
                li {
                  &:last-child {
                    border-bottom: 1px solid $color--white;
                  }
                }
              }
              &__level1 {
                background-color: $color--pink-tier1;
              }
              &__level2 {
                background-color: $color--red-tier2;
              }
              &__level3 {
                background-color: $color--gray-tier3;
              }
            }
          }
          &-title {
            margin: 0;
            font-size: 18px;
            letter-spacing: 0.8px;
            font-family: $ano-bold_regular-font;
          }
          &-level {
            margin: 0;
            font-family: $ano-bold_regular-font;
            font-size: 10px;
            text-transform: uppercase;
          }
          &-status {
            margin: 0;
            font-family: $ano-bold_regular-font;
            font-size: 9px;
            text-align: center;
          }
        }
        .macselect-benefits__section {
          &-levels__header-title {
            word-break: break-all;
          }
          &-levels__header-status {
            font-size: 11px;
          }
          &-fields {
            width: 60%;
            float: #{$ldirection};
            .macselect-benefits__section {
              &-title {
                height: 160px;
                padding-top: 20px;
                h1 {
                  margin: 0;
                }
              }
              &-copy {
                ul {
                  list-style: none;
                  li {
                    font-family: $ano-bold_regular-font;
                    border-bottom: 1px solid $color--border;
                    padding: 13px 0;
                    margin: 0;
                    height: 90px;
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;
                    font-size: 14px;
                    .content {
                      &__next {
                        font-family: $roboto-mono_regular-font;
                        font-size: 10px;
                        text-transform: initial;
                      }
                    }
                    &:nth-child(3) {
                      @include breakpoint($bp--large-up) {
                        padding-top: 13px;
                      }
                      padding-top: 21px;
                    }
                    &:last-child {
                      border: none;
                    }
                    p {
                      text-transform: initial;
                      margin: 0;
                    }
                  }
                }
              }
            }
          }
          &-levels {
            width: 40%;
            display: inline-block;
            &__header {
              height: 160px;
              font-family: $ano-bold_regular-font;
              &-title {
                margin: 0;
                font-size: 22px;
                font-family: $ano-bold_regular-font;
                letter-spacing: 0.5px;
              }
              &-level {
                text-transform: uppercase;
                margin: 0;
                font-size: 14px;
              }
              &-status {
                margin: 0;
                font-size: 10px;
              }
            }
            .macselect-benefits__section {
              &-level1,
              &-level2,
              &-level3,
              &-level4 {
                width: 33.33%;
                border-#{$ldirection}: none;
                float: #{$ldirection};
                .macselect-benefits__section {
                  &-levels__header {
                    color: $color--white;
                    padding: 10px;
                  }
                  &-copy {
                    li {
                      height: 90px;
                      border-bottom: 1px solid $color--border;
                      border-#{$ldirection}: 1px solid $color--border;
                      padding-top: 20px;
                      span {
                        border-radius: 10px;
                        width: 20px;
                        height: 20px;
                        margin: 0 auto;
                        display: block;
                      }
                      &:last-child {
                        border-bottom: none;
                      }
                    }
                  }
                }
              }
              &-level1 {
                .macselect-benefits__section {
                  &-levels__header {
                    background-color: $color--pink-tier1;
                  }
                  &-copy {
                    li {
                      span {
                        background-color: $color--pink-tier1;
                      }
                    }
                  }
                }
              }
              &-level2 {
                .macselect-benefits__section {
                  &-levels__header {
                    background-color: $color--red-tier2;
                  }
                  &-copy {
                    li {
                      span {
                        background-color: $color--red-tier2;
                      }
                    }
                  }
                }
              }
              &-level3 {
                .macselect-benefits__section {
                  &-levels__header {
                    background-color: $color--gray-tier3;
                  }
                  &-copy {
                    li {
                      span {
                        background-color: $color--gray-tier3;
                      }
                    }
                  }
                }
              }
              &-level4 {
                border-#{$rdirection}: 1px solid $color--border;
                .macselect-benefits__section {
                  &-levels__header {
                    background-color: $color--gray-tier3;
                  }
                  &-copy {
                    li {
                      span {
                        background-color: $color--gray-tier3;
                      }
                    }
                  }
                }
              }
            }
          }
          &-title {
            width: 230px;
          }
          &-level1 {
            .macselect-benefits__section {
              &-levels__header {
                background-color: $color--light-pink;
              }
              &-copy {
                li {
                  span {
                    background-color: $color--light-pink;
                  }
                }
              }
            }
          }
          &-level2 {
            .macselect-benefits__section {
              &-levels__header {
                background-color: $color--red-tier2;
              }
              &-copy {
                li {
                  span {
                    background-color: $color--red-tier2;
                  }
                }
              }
            }
          }
          &-level3 {
            .macselect-benefits__section {
              &-levels__header {
                background-color: $color--dark-gray;
              }
              &-copy {
                li {
                  span {
                    background-color: $color--dark-gray;
                  }
                }
              }
            }
          }
          &-level1,
          &-level2,
          &-level3 {
            width: 33.33%;
          }
          &-copy {
            ul {
              li {
                height: 90px;
                padding: 13px 5px;
                display: flex;
                align-items: center;
                .text_content {
                  background: none;
                  width: auto;
                  text-align: center;
                }
              }
            }
          }
        }
        .macselect-benefits__initial {
          li {
            width: 100%;
            justify-content: center;
            border: 1px solid $color--border;
            text-align: center;
          }
          &-item {
            border-top: none !important;
          }
        }
      }
      &-benefits-link {
        margin-top: 40%;
      }
      &__content,
      &__romance {
        @include breakpoint($bp--medium-landscape) {
          margin: 0 10%;
        }
      }
    }
  }
  .mac-select-marketing-page {
    &__level__up {
      position: relative;
    }
  }
}
