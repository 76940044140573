.spp-cross-sells {
  .sspp-cross-sells__content {
    .product-brief {
      &__footer {
        min-height: 45px;
      }
    }
  }
}

.site-footer {
  .block--footer-mobile-help {
    .grid--item {
      float: none;
      padding-bottom: 20px;
      width: 100%;
    }
  }
}

.appt-book {
  .confirm {
    .mobile-phone-fieldset {
      .appt-book-mobile-prefix {
        width: 30% !important;
      }
      .appt-book-mobile {
        width: 70% !important;
      }
    }
  }
}

body {
  &.toolbar-drawer {
    padding-top: 0 !important;
  }
}

.site-container {
  .account-order-history {
    .past-purchases {
      &__item {
        .product-header {
          h6 {
            font-size: 11px;
            &.quantity {
              margin-left: 29%;
              width: 25%;
            }
          }
          .rating {
            width: 24%;
            margin-left: 52%;
          }
          .price {
            margin-right: -4%;
          }
        }
        .product--teaser {
          .product {
            &__qty {
              margin-left: 33%;
            }
            &__rate {
              width: 14%;
              margin-left: 55%;
            }
            &__detail {
              width: 23%;
              margin-left: 10%;
            }
            &__price {
              margin-left: 75.22222%;
            }
            &__add-to-bag {
              font-size: 8px;
            }
            &__add {
              margin-right: -3%;
            }
          }
        }
      }
    }
  }
}

.checkout {
  .site-container {
    .checkout__sidebar {
      margin-bottom: 60px;
    }
  }
}
