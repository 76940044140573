@if $loyalty-redesign == true {
  .loyalty__romance__with__rewards__details {
    @include breakpoint($bp--large-up) {
      width: 100%;
      max-width: $width-xxlarge;
      margin: 0 auto;
    }
    .loyalty__lover {
      @include breakpoint($bp--large-up) {
        margin: 105px 0;
        padding: 0 60px;
      }
      margin-top: 72px;
    }
    .benefits {
      &__title {
        @include breakpoint($bp--large-up) {
          font-size: 53px;
          padding: 0;
        }
        font-family: $ano-bold_regular-font;
        letter-spacing: -0.02em;
        font-size: 42px;
        line-height: 0.9;
        margin-bottom: 8px;
        padding: 0 6%;
      }
      &__subtitle {
        @include breakpoint($bp--large-up) {
          padding: 0;
        }
        font-family: $helvetica-font;
        font-size: 13px;
        margin-bottom: 35px;
        line-height: 2;
        padding: 0 6%;
      }
    }
    .loyalty__level__image {
      width: 100%;
    }
    .loyalty__level__1,
    .loyalty__level__2,
    .loyalty__level__3,
    .loyalty__level__4 {
      position: relative;
      width: 100%;
      max-width: 1281px;
      margin: 0 auto;
      display: table;
      .benefits__details {
        @include breakpoint($bp--large-up) {
          #{$ldirection}: 48%;
          bottom: 34%;
          padding: 0;
          max-width: 43%;
        }
        @include breakpoint($bp--medium-portrait) {
          #{$ldirection}: 37%;
          max-width: 62%;
        }
        @include breakpoint($width-xlarge) {
          bottom: 18%;
        }
        @include breakpoint($width-xxlarge) {
          bottom: 34%;
        }
        width: 100%;
        position: absolute;
        padding: 0 7%;
        bottom: 14%;
        .learn__more-link {
          @include breakpoint($bp--large-up) {
            top: 35px;
            position: relative;
          }
          font-family: $ano-black_regular-font;
          font-weight: bold;
          font-size: 17px;
          cursor: pointer;
          text-decoration: underline;
          border-bottom: none;
          &:hover,
          &:visited,
          &:focus {
            color: $color--black;
          }
        }
        .level {
          &__name {
            font-family: $ano-bold_regular-font;
            font-size: 24px;
            letter-spacing: -0.07em;
          }
          &__text {
            @include breakpoint($bp--large-up) {
              font-size: 13px;
              padding-bottom: 50px;
            }
            font-family: $roboto-mono_regular-font;
            font-size: 15px;
            padding-bottom: 30px;
          }
        }
        &__offers {
          position: relative;
          @include breakpoint($bp--large-up) {
            display: table;
            width: 65%;
          }
          display: flex;
          flex: 1 0 100%;
          flex-wrap: wrap;
          .item__content {
            @include breakpoint($bp--large-up) {
              padding: 0 25px;
              width: auto;
            }
            @include breakpoint($bp--medium-portrait) {
              width: auto;
            }
            @include breakpoint($bp--xlarge-up) {
              max-width: 61px;
            }
            display: table-cell;
            text-align: center;
            flex: 0 0 auto;
            width: 33.33%;
            padding-bottom: 30px;
            @include breakpoint($bp--large-up) {
              &.icon__section {
                &__3 {
                  padding: 0 21px;
                  &:first-child {
                    padding-#{$ldirection}: 16px;
                  }
                }
                &__4 {
                  padding: 0 23px;
                }
                &__5 {
                  padding: 0 5px;
                  &:first-child {
                    padding-#{$ldirection}: 1px;
                  }
                }
                &__6 {
                  padding: 0 16px;
                }
                &__7 {
                  padding: 0 9px;
                  &:first-child {
                    padding-#{$ldirection}: 0;
                  }
                }
                &__4,
                &__6 {
                  &:first-child {
                    padding-#{$ldirection}: 7px;
                  }
                }
              }
            }
            img {
              @include breakpoint($bp--large-up) {
                height: auto;
                width: 44px;
              }
              height: 31px;
            }
            .icon__content {
              padding-top: 15px;
              font-size: 9px;
            }
          }
        }
      }
    }
    .loyalty__level__2 {
      .benefits__details__offers {
        .item__content {
          @include breakpoint($bp--large-up) {
            padding: 0 20px;
          }
        }
      }
    }
    .loyalty__level__3 {
      .benefits__details__offers {
        .item__content {
          @include breakpoint($bp--large-up) {
            padding: 0 12px;
          }
        }
      }
    }
    .loyalty__level__2,
    .loyalty__level__3,
    .loyalty__level__4 {
      .level {
        &__name,
        &__text {
          color: $color--full-white;
        }
      }
      .benefits__details {
        @include breakpoint($bp--large-up) {
          #{$ldirection}: 48%;
          bottom: 30%;
        }
        @include breakpoint($bp--medium-portrait) {
          bottom: 5%;
        }
        @include breakpoint($width-xlarge) {
          bottom: 13%;
        }
        @include breakpoint($width-xxlarge) {
          bottom: 30%;
        }
        position: absolute;
        bottom: 12%;
        &__offers {
          @include breakpoint($width-xxlarge) {
            width: 100%;
          }
          color: $color--full-white;
        }
        .learn__more-link {
          color: $color--full-white;
          &:hover,
          &:visited,
          &:focus {
            color: $color--full-white;
          }
        }
      }
    }
  }
}
