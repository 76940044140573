.accordionPanel {
  header {
    cursor: pointer;
  }
  i {
    float: #{$rdirection};
    @include icon(arrow--down);
  }
  .active i {
    @include icon(arrow--up);
  }
}
