#loyalty__page__index-bottom {
  visibility: visible;
}

.loyalty__content-bottom {
  &__section-header {
    display: block;
  }
}

.macselect-benefits__section-levels {
  .macselect-benefits {
    &__section-copy {
      ul {
        li {
          flex-direction: column;
          font-size: 12px;
          text-align: center;
        }
      }
    }
  }
}

.loyalty__content-bottom,
.loyalty-select__bottom {
  .mac-select-marketing-page__level__up {
    .macselect-benefits__container {
      .macselect-benefits__section-levels {
        width: 50%;
        .macselect-benefits__section {
          &-level1,
          &-level2,
          &-level3,
          &-level4 {
            width: 25%;
          }
          &-level1 {
            .macselect-benefits__section {
              &-levels__header {
                background-color: $color--pink-tier1;
              }
              &-copy {
                li {
                  span {
                    background-color: $color--pink-tier1;
                  }
                }
              }
            }
          }
          &-level2 {
            .macselect-benefits__section {
              &-levels__header {
                background-color: $color-purple-dark;
              }
              &-copy {
                li {
                  span {
                    background-color: $color-purple-dark;
                  }
                }
              }
            }
          }
          &-level3 {
            .macselect-benefits__section {
              &-levels__header {
                background-color: $color--red-tier2;
              }
              &-copy {
                li {
                  span {
                    background-color: $color--red-tier2;
                  }
                }
              }
            }
          }
          &-level4 {
            .macselect-benefits__section {
              &-levels__header {
                background-color: $color--gray-tier3;
              }
              &-copy {
                li {
                  span {
                    background-color: $color--gray-tier3;
                  }
                }
              }
            }
          }
        }
      }
      .macselect-benefits__section-fields {
        width: 50%;
      }
      .macselect-benefits {
        &__section-levels__header-title {
          letter-spacing: -1px;
        }
      }
    }
  }
}

.loyalty__romance {
  &__with__rewards__details {
    .loyalty__level__1 {
      .benefits__details {
        color: $color--full-white;
        bottom: 2%;
        @include breakpoint($bp--medium-up) {
          bottom: 28%;
        }
        &__offers {
          @include breakpoint($bp--medium-up) {
            width: 100%;
          }
        }
        .learn__more-link {
          color: $color--full-white;
          &:focus,
          &:visited,
          &:hover {
            color: $color--full-white;
          }
        }
      }
      .loyalty__level__image {
        height: 650px;
        @include breakpoint($bp--medium-up) {
          height: auto;
        }
      }
    }
    .loyalty__level__2 {
      .loyalty__level__image {
        height: 650px;
        @include breakpoint($bp--medium-up) {
          height: auto;
        }
      }
      .benefits__details {
        bottom: 2%;
        @include breakpoint($bp--medium-up) {
          bottom: 28%;
        }
        &__offers {
          @include breakpoint($bp--medium-up) {
            width: 100%;
          }
        }
      }
    }
    .loyalty__level__3 {
      .loyalty__level__image {
        height: 650px;
        @include breakpoint($bp--medium-up) {
          height: auto;
        }
      }
      .benefits__details {
        bottom: 2%;
        @include breakpoint($bp--medium-up) {
          bottom: 28%;
        }
        &__offers {
          @include breakpoint($bp--medium-up) {
            width: 100%;
          }
        }
      }
    }
    .loyalty__level__4 {
      .benefits__details {
        bottom: 30%;
        color: $color--full-white;
        @include breakpoint($bp--medium-up) {
          bottom: 28%;
        }
        &__offers {
          @include breakpoint($bp--medium-up) {
            width: 100%;
          }
        }
      }
    }
  }
}

.progress_bar {
  display: flex;
  @include swap_direction(padding, 0 8%);
  flex-direction: column;
  @include breakpoint($bp--medium-up) {
    flex-direction: row;
  }
  &_right {
    position: relative;
    margin: 0 auto;
    .total_progress {
      position: absolute;
      top: 190px;
      #{$ldirection}: 90px;
    }
    .points__expiration {
      text-align: center;
    }
  }
  &_text {
    position: absolute;
    #{$ldirection}: 50px;
    top: 40px;
    .points_current {
      text-align: center;
      font-size: 40px;
    }
    .points_header {
      text-align: center;
    }
    .reward_status {
      width: 125px;
      font-weight: 700;
      word-break: break-word;
      margin: 0;
    }
    .current_tier {
      text-align: center;
      font-weight: 700;
      &_level1 {
        color: $color--pink-tier1;
      }
      &_level2 {
        color: $color-purple-dark;
      }
      &_level3 {
        color: $color--red-tier2;
      }
      &_level4 {
        color: $color--gray-tier3;
      }
    }
  }
  &_left {
    width: 50%;
    &--links-about,
    &--links-faq {
      width: auto;
      text-decoration: underline;
      border-bottom: none;
      font-size: 18px;
    }
    &--links-faq {
      display: block;
    }
  }
}

.loyalty__header-banner--image {
  width: 100%;
  display: block;
}

.loyalty__panel__offers {
  &__sku__list-container {
    .tabbed-rewards-block__tab {
      width: 20%;
    }
    .product__rating-stars-rating {
      width: 100% !important;
    }
  }
}

.mac-lover__events {
  &__promos--header-link {
    line-height: 1;
  }
}

.loyalty__content-bottom__section,
.loyalty-select__bottom {
  .mac-select__marketing-page {
    .macselect-benefits {
      &__container__content__left {
        .macselect-benefits {
          &__container__content__level1 {
            height: 320px;
            background-color: $color--pink-tier1;
          }
          &__container__content__level2 {
            height: 422px;
            background-color: $color-purple-dark;
            .arrow {
              border-left: 35px solid $color-purple-dark;
              border-right: 35px solid $color-purple-dark;
              border-top: 20px solid $color--pink-tier1;
            }
          }
          &__container__content__level3 {
            height: 424px;
            background-color: $color--red-tier2;
            border-bottom: none;
            .arrow {
              border-left: 35px solid $color--red-tier2;
              border-right: 35px solid $color--red-tier2;
              border-top: 20px solid $color-purple-dark;
            }
          }
          &__container__content__level4 {
            height: 318px;
            position: relative;
            .lover__container {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translateX(-50%) translateY(-50%) rotate(-90deg);
              display: table;
            }
          }
        }
      }
      &__container__content__right {
        .macselect-benefits__container {
          &__content__level1 {
            background-color: $color--pink-tier1;
          }
          &__content__level2 {
            background-color: $color-purple-dark;
          }
          &__content__level3 {
            background-color: $color--red-tier2;
          }
          &__content__level4 {
            background-color: $color--gray-tier3;
          }
        }
      }
    }
  }
}

.field-container {
  .loyalty-name_details {
    @include breakpoint($bp--medium-up) {
      display: flex;
    }
    #form--registration {
      &_short--field--LAST_NAME {
        @include breakpoint($bp--medium-up) {
          margin-#{$ldirection}: 10px;
        }
      }
    }
  }
  .loyalty-birth_details {
    @include breakpoint($bp--medium-up) {
      display: flex;
    }
    .form-item_month_year {
      @include breakpoint($bp--medium-up) {
        display: flex;
      }
      .form-item {
        @include breakpoint($bp--medium-up) {
          margin-#{$ldirection}: 10px;
        }
        .select2-choice {
          @include breakpoint($bp--medium-up) {
            height: 41px;
            width: 100%;
          }
          .select2-arrow {
            top: 5px;
            #{$rdirection}: 0;
          }
        }
        .select2-chosen {
          @include breakpoint($bp--medium-up) {
            line-height: 2.5;
          }
        }
      }
    }
    .form-item_birthday_label {
      @include breakpoint($bp--medium-up) {
        padding-#{$ldirection}: 15px;
      }
    }
  }
  .field-container {
    .loyalty-user__promotion-email {
      width: 100%;
    }
    .picker-checkbox {
      display: flex;
    }
    #form--sms--field--MOBILE_NUMBER {
      @include breakpoint($bp--medium-up) {
        width: 50%;
      }
    }
  }
}

.loyalty__panel__offers__sku {
  position: relative;
  margin-top: 30px;
  &__header-container {
    position: absolute;
    z-index: 100;
    color: $color--full-white;
    @include swap_direction(padding, 10% 8%);
  }
  .loyalty__panel__offers--image {
    position: relative;
  }
  &__header__greet {
    text-transform: uppercase;
    letter-spacing: 5px;
  }
  &__header__title {
    width: 63%;
    line-height: 1;
  }
}

.loyalty-offer-code-panel {
  .offer-code-content__wallet {
    .offer-code-wallet__code {
      &__description-description {
        min-height: 75px;
      }
      &-info--applied {
        width: 180px;
        margin: 0 auto;
        @include breakpoint($bp--xlarge-up) {
          @include swap_direction(margin, 0 auto);
          width: 125px;
        }
        @include breakpoint($bp--xxlarge-up) {
          @include swap_direction(padding, 0 30px);
          width: 180px;
        }
      }
      &__button-container {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        .btn[disabled] {
          pointer-events: none;
          cursor: not-allowed;
        }
        .btn {
          &.disabled {
            pointer-events: none;
            cursor: not-allowed;
            opacity: 1;
            background-color: $color--gray--lightest;
          }
        }
      }
      &__description-points {
        text-align: center;
      }
      &__description-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 15px;
        height: 30px;
        text-align: center;
      }
      &__image-container {
        img {
          width: 170px;
          height: auto;
        }
      }
    }
    .slick-prev {
      position: absolute;
      top: 50%;
    }
  }
  .loyalty-offer-code {
    &-panel__title {
      display: block;
    }
    &-panel__points {
      text-align: center;
    }
  }
  .offer-code-wallet__item {
    .product_shade {
      height: 40px;
      text-align: center;
    }
  }
}

.accordionPanel {
  .loyalty-offer-code-panel {
    i {
      float: #{$rdirection};
      position: relative;
      bottom: 5px;
      #{$rdirection}: 16px;
    }
  }
}

.product-brief-carousel {
  &.grid--mpp__carousel {
    .loyalty__panel__offers__offer-image {
      margin: 0 auto;
      padding: 0 10px;
    }
    .slick-next,
    .slick-prev {
      &.slick-disabled {
        opacity: 0.2;
      }
      &::before {
        display: none;
      }
    }
    .slick-next {
      background: url('/media/export/cms/loyalty/pc-rewards-right-arrow.png') no-repeat;
    }
    .slick-prev {
      background: url('/media/export/cms/loyalty/pc-rewards-left-arrow.png') no-repeat;
      position: absolute;
      top: 50%;
    }
    .loyalty__panel {
      &__offers__offer-header {
        font-size: 16px;
        margin-top: 20px;
        min-height: 60px;
      }
      &__offers__offer-description {
        min-height: 60px;
      }
    }
  }
}

.mac-select__account-panel {
  &__header-left {
    float: #{$ldirection};
  }
  &__header-right {
    display: flex;
    flex-direction: column;
    position: absolute;
    #{$rdirection}: 50px;
  }
  &__content-spend,
  &__content-processing {
    clear: both;
  }
  &__content {
    position: relative;
  }
}

.account-nav {
  .account-nav__section {
    .mac-select__account-panel {
      &__content-title {
        .mac-select {
          &__color-tier1 {
            color: $color--pink-tier1;
          }
          &__color-tier2 {
            color: $color-purple-dark;
          }
          &__color-tier3 {
            color: $color--red-tier2;
          }
          &__color-tier4 {
            color: $color--gray-tier3;
          }
        }
      }
      &__content-details {
        clear: both;
      }
    }
  }
}

#loyalty__panel__transactions {
  .loyalty__panel {
    &__transactions__list {
      &__column-spent__points-spent {
        text-align: justify;
      }
      .loyalty__panel__transactions {
        &__list__column-plus,
        &__list__column-minus {
          border: none;
        }
      }
    }
  }
}

.site-my-mac__contents {
  .mac-select {
    &__color-tier1 {
      color: $color--pink-tier1;
    }
    &__color-tier2 {
      color: $color-purple-dark;
    }
    &__color-tier3 {
      color: $color--red-tier2;
    }
    &__color-tier4 {
      color: $color--gray-tier3;
    }
  }
}

.checkout__content {
  .loyalty__points-earned {
    color: $color--black;
  }
}

.loyalty-user__create-account {
  .checkbox {
    .label {
      max-width: 100%;
    }
  }
}

.loyalty-user {
  &__promotion-terms {
    margin: 15px 0;
  }
}

.loyalty__panel {
  &__transactions__paginate {
    text-align: center;
    padding: 10px;
    .loyalty__panel__transactions {
      &__list__paginate__page-previous {
        padding-#{$rdirection}: 20px;
        border: 0;
        &.prev_disabled {
          pointer-events: none;
          color: $color--gray--lighter;
        }
      }
      &__list__paginate__page-next {
        border: 0;
        &.next_disabled {
          pointer-events: none;
          color: $color--gray--lighter;
        }
      }
    }
  }
}

.cr21-refresh {
  &.site-header-formatter {
    .site-header-formatter__utilities--loyalty {
      .site-header-formatter__utilities--loyalty-link {
        line-height: 2.5 !important;
      }
    }
  }
}

.is-eligible {
  .mac-select__marketing-page__top-col1 {
    display: block;
  }
  .benefits__container {
    position: relative;
    top: 50px;
    .benefits__btn {
      width: 300px;
    }
  }
}

.registration-panel-loyalty {
  display: flex;
  flex-direction: column;
  @include breakpoint($bp--medium-up) {
    flex-direction: row;
  }
  .panel--loyalty-join__logo-section {
    width: 100%;
    text-align: center;
    margin-top: 15%;
    @include breakpoint($bp--medium-up) {
      padding-#{$rdirection}: 30px;
    }
    .panel--loyalty-join__logo {
      .loyalty__logo {
        max-width: 100%;
        height: auto;
      }
    }
  }
  .loyalty-benefits {
    li {
      line-height: 1.5;
      span {
        font-size: 12px;
      }
    }
  }
}

.checkout-confirmation-page {
  position: relative;
  .panel {
    .checkout__sidebar {
      &.right {
        @include breakpoint($bp--medium-up) {
          position: relative;
          #{$ldirection}: 93px;
        }
      }
    }
  }
  li {
    &.link {
      margin-bottom: 0;
    }
  }
  .checkout__sidebar {
    &.right {
      @include breakpoint($bp--medium-up) {
        position: absolute;
        top: 68%;
        #{$rdirection}: 172px;
      }
    }
  }
}

.button {
  &.continue_shopping {
    color: $color--full-white;
  }
}

a.loyalty__panel__offers__sku__loader__button {
  &.loyalty__panel__product__button {
    &.button {
      &.btn-shopnow {
        color: $white;
      }
    }
  }
  &.loyalty__panel__offers__sku__remove {
    &.button--offers {
      &.button {
        color: $white;
      }
    }
  }
}

a.loyalty__panel__offers__button {
  &.loyalty__panel__product__button {
    &.button--offers.button {
      color: $white;
    }
  }
}

#loyalty__page__index {
  #loyalty__panel__offers {
    display: none;
  }
  .loyalty__panel__offers__sku__product {
    &__image-container {
      @include breakpoint($width-xxlarge) {
        min-height: 300px;
      }
      img {
        @include breakpoint($width-xxlarge) {
          max-height: 300px;
        }
      }
    }
    &__offer-code {
      @include breakpoint($width-xxlarge) {
        margin-bottom: 30px;
      }
    }
    .loyalty__panel__offers__cta {
      .loyalty__panel__offers__button {
        color: $color--full-white;
        &.button--disabled {
          pointer-events: none;
          cursor: not-allowed;
          opacity: 1;
          background-color: $color--gray--lightest;
        }
      }
    }
    &__header {
      min-height: auto;
      margin-top: 10px;
    }
    &__shade-name {
      font-size: 16px;
      margin: 10px 0;
    }
  }
  .loyalty__panel__offers__sku {
    .loyalty__panel__offers__sku {
      &__header__greet {
        font-size: 25px;
      }
      &__header__subtitle {
        font-size: 20px;
      }
    }
  }
}

.sign-in-page {
  .divider {
    .social-login__divider {
      @include breakpoint($bp--medium-up) {
        position: static;
      }
      &::before {
        @include breakpoint($bp--medium-up) {
          width: 0;
        }
      }
    }
  }
  .info-icon-right {
    .info-icon {
      @include swap_direction(padding, 2px 0 0 8px);
      width: 20px;
      height: 20px;
      text-decoration: none;
    }
  }
  .loyalty-inputs {
    &.field-container {
      @include breakpoint($bp--medium-up) {
        width: 92%;
      }
      .birth_month {
        @include breakpoint($bp--medium-up) {
          width: 70%;
        }
      }
      .birth_day {
        @include breakpoint($bp--medium-up) {
          width: 30%;
        }
      }
    }
  }
}

.signin-hidden {
  display: none !important;
}

.mac-select__marketing-page__top {
  .btn {
    @include breakpoint($bp--medium-up) {
      width: 54%;
    }
    @include breakpoint($bp--xxlarge-up) {
      width: 42%;
    }
  }
}
