@if $loyalty-redesign == true {
  .loyalty__index__container {
    @include breakpoint($bp--large-up) {
      max-width: inherit;
      margin: 0;
    }
  }
  #loyalty__panel__points {
    &.loyalty__panel {
      border-bottom: none;
      .mac-select__account-panel__progress {
        @include breakpoint($bp--large-up) {
          padding: 0 8%;
        }
        border-bottom: none;
        padding: 0;
        margin: 0;
        max-width: inherit;
      }
    }
    .points__details__section {
      max-width: inherit;
      padding-top: 0;
      &-left,
      &-right {
        @include breakpoint($bp--large-up) {
          float: #{$ldirection};
          padding: 0;
        }
        padding: 0 10px;
      }
      &-left {
        @include breakpoint($bp--large-up) {
          width: 70%;
        }
      }
      &-right {
        @include breakpoint($bp--large-up) {
          width: 30%;
        }
      }
      .loyalty__tier {
        @include breakpoint($bp--large-up) {
          display: inline-block;
        }
        font-family: $ano-bold_regular-font;
        font-size: 25px;
        padding-top: 35px;
        text-transform: uppercase;
        letter-spacing: -0.02em;
        margin-bottom: 0;
        text-align: #{$ldirection};
      }
      .loyalty__spend {
        @include breakpoint($bp--large-up) {
          margin: 0 0 30px;
          font-size: 13px;
        }
        font-family: $helvetica-font;
        font-size: 15px;
        text-align: #{$ldirection};
        text-transform: uppercase;
        margin: 10px 0 30px;
      }
      .anniversary {
        &__about__navigation {
          margin-top: 20px;
        }
        &__faq__navigation {
          margin: 0;
        }
        &__about__navigation,
        &__faq__navigation {
          text-align: #{$ldirection};
          display: block;
          .link {
            border-bottom-color: $color--black;
            font-family: $ano-black_regular-font;
            margin-bottom: 12px;
            font-size: 15px;
            padding: 0;
          }
        }
        &__details {
          @include breakpoint($bp--large-up) {
            padding: 20px 0 40px;
          }
          padding: 20px 0 30px;
          font-family: $helvetica-font;
          font-size: 13px;
        }
      }
      &-left {
        @include breakpoint($bp--large-up) {
          text-align: #{$ldirection};
        }
        p {
          &:empty {
            display: none;
          }
        }
        .points__nav__link {
          @include breakpoint($bp--large-up) {
            display: block;
          }
          display: none;
        }
        .account__subtitle {
          font-family: $ano-bold_regular-font;
          font-size: 15px;
          text-align: #{$ldirection};
          letter-spacing: -0.02em;
        }
        .level__name {
          @include breakpoint($bp--large-up) {
            display: inline;
          }
          font-family: $ano-bold_regular-font;
          font-size: 25px;
          text-transform: uppercase;
          letter-spacing: -0.02em;
          margin-bottom: 0;
          text-align: #{$ldirection};
        }
      }
      &-right {
        position: relative;
        .chart__img {
          width: 270px;
        }
        .points__nav__link {
          @include breakpoint($bp--large-up) {
            display: none;
          }
          display: block;
        }
        .rewards {
          @include breakpoint($bp--large-up) {
            top: 25%;
          }
          position: absolute;
          top: 20%;
          #{$rdirection}: 0;
          text-align: center;
          width: 100%;
          .mac-tier1,
          .mac-tier2,
          .mac-tier3 {
            font-family: $ano-bold_regular-font;
            font-size: 12px;
            letter-spacing: -0.02em;
            margin-bottom: 0;
            text-transform: uppercase;
          }
          .mac-tier1 {
            color: $color--pink-tier1;
          }
          .mac-tier3 {
            color: $color--gray-tier3;
          }
          .mac-tier2 {
            color: $color--red-shade;
          }
          .loyalty {
            &__points {
              font-family: $ano-bold_regular-font;
              font-size: 55px;
              letter-spacing: -0.03em;
              margin-bottom: 0;
              line-height: 0.8;
              &__txt {
                font-family: $ano-bold_regular-font;
                letter-spacing: -0.02em;
                font-size: 22px;
                margin: 0;
                line-height: 1;
              }
            }
          }
          .rewards__status {
            font-family: $ano-bold_regular-font;
            font-size: 12px;
            letter-spacing: -0.02em;
            margin: 0;
          }
        }
      }
      .mac__lover__logo {
        @include breakpoint($bp--large-up) {
          margin-top: 15px;
        }
        max-width: inherit;
        margin-bottom: 7px;
        width: auto;
        img {
          display: block;
        }
      }
    }
  }
  .lover__tape__img {
    @include breakpoint($bp--large-up) {
      background-image: url('/media/export/cms/loyalty/mac_ribbons_pc.jpg');
      height: 190px;
    }
    @include breakpoint($bp--medium-portrait) {
      background-image: url('/media/export/cms/loyalty/mac_ribbons_pc.jpg');
    }
    background-repeat: no-repeat;
    height: 135px;
    background-size: contain;
    background-image: url('/media/export/cms/loyalty/tout-mobile-tape-bg.png');
  }
  .loyalty-loading {
    padding: 50px;
    text-align: center;
  }
}
