.sign-in-page,
.site-my-mac-v1 {
  .social-login {
    &__terms {
      text-align: $ldirection;
      padding: 0;
      @include breakpoint($bp--medium-up) {
        margin-bottom: 15px;
      }
    }
    &__email-opt-in {
      padding: 0;
    }
    &__opt-in-label {
      @include breakpoint($bp--large-up) {
        float: $ldirection;
      }
    }
    .picker-label {
      text-align: $ldirection;
      float: none;
    }
  }
  .gnav {
    .social-login__email-opt-in {
      padding: 20px 0 1px 0;
    }
  }
}

.social-login {
  .picker.picker-checkbox {
    .picker-handle {
      margin-top: 10px;
    }
  }
  .picker-label {
    float: none;
    text-align: $ldirection;
    margin-#{$ldirection}: 0;
  }
  label {
    display: block;
    text-transform: none;
    text-align: $ldirection;
  }
}
/* Profile preference page */
.social-info {
  .picker.picker-checkbox {
    .picker-label {
      float: none;
      text-align: $ldirection;
      margin-#{$ldirection}: 39px;
    }
  }
  &__connect {
    line-height: 20px;
  }
}

.social-login.gnav {
  input#facebook__email-opt-in-register-panel,
  input#fb-field-disclaimer {
    width: auto;
    height: auto;
  }
}

.sign-in-panel {
  .social-login {
    &__terms {
      text-align: $ldirection;
      padding: 0;
    }
    &__email-opt-in {
      padding-top: 25px;
      @include breakpoint($bp--medium-up) {
        padding-top: 12px;
      }
      @include breakpoint($bp--large-up) {
        padding-top: 0;
      }
    }
  }
  .picker-label {
    text-align: $ldirection;
    float: none;
  }
}

.checkout-confirmation-page {
  .social-login {
    .picker {
      .picker-label {
        float: none;
      }
    }
    .tooltip {
      display: none;
    }
  }
}
/* SignIn page styling */
.sign-in-page {
  @include breakpoint($bp--medium-up) {
    margin-bottom: 100px;
    .new-account,
    .return-user {
      margin-#{$rdirection}: 20px;
    }
    .field-container--grid .form-item {
      float: none;
      margin-#{$rdirection}: 0;
      padding-#{$rdirection}: 0;
      clear: both;
    }
    .sign-in-container {
      width: 50%;
    }
    .fb-social-tooltip-container {
      display: none;
    }
    .social-login-section {
      margin-top: 120px;
      .social-terms__container {
        .social-login__terms {
          padding-bottom: 0;
        }
      }
      .picker-label {
        float: none;
        text-align: left;
        margin-#{$ldirection}: 39px;
      }
      .fb-disclaimer-error {
        @include breakpoint($bp--medium-up) {
          margin-#{$ldirection}: 10%;
        }
      }
    }
    .divider {
      .social-login__divider {
        &:before {
          #{$ldirection}: 100%;
        }
        .inner {
          #{$ldirection}: inherit;
        }
      }
    }
  }
  .social-login-section {
    .social-login {
      &__email_opt_in {
        padding-top: 0;
      }
      &__opt-in-label {
        margin-#{$ldirection}: 35px;
      }
    }
  }
}
/* Checkout page styling */
.checkout {
  &__content {
    .signin-panel,
    .sign-in-panel {
      .social-login {
        &__divider {
          @include swap_direction(margin, 4px auto 12px);
          @include breakpoint($bp--medium-up) {
            @include swap_direction(margin, 25px auto 25px);
          }
        }
        &__container {
          padding-bottom: 4px;
          @include breakpoint($bp--medium-up) {
            padding: 0;
          }
        }
      }
    }
  }
}
/* Displaying or-divider in EMEA */
.field-container-section {
  .divider {
    display: block;
  }
}
/* Appointment booking */
.field-collection-slides {
  .appt-book {
    .appt-book-sections-container {
      .appt-book-section {
        .sign-in-form-container {
          .social-login {
            .fb-disclaimer-container {
              margin-top: 20px;
            }
            input {
              width: auto;
              display: inline-block;
              float: $ldirection;
              height: auto;
              margin: 3px 0 0;
              padding: 0;
            }
            label {
              display: block;
              text-align: $ldirection;
              margin-#{$ldirection}: 25px;
            }
            &__opt-in-label {
              margin-top: 20px;
            }
            &__terms {
              padding-#{$ldirection}: 0;
              text-align: $ldirection;
            }
          }
        }
      }
    }
  }
}

#cboxWrapper {
  .social-login {
    &__terms {
      text-align: $ldirection;
      padding-#{$ldirection}: 0;
    }
  }
}

.site-header {
  .site-header__tools {
    .site-my-mac__contents {
      overflow-y: scroll;
      height: 500px;
      width: 400px;
    }
  }
}
/* Write a review page styling */
#power_review_container {
  .social-login {
    &__email-opt-in {
      padding: 0;
    }
    .picker-label {
      float: none;
      text-align: left;
      margin-#{$ldirection}: 39px;
    }
    &__opt-in-label {
      margin-left: 30px;
    }
    &__terms {
      p {
        margin: 0;
      }
    }
  }
}
/* Tooltip */
.tooltip {
  text-align: left;
  margin-top: 10px;
  position: relative;
  display: inline-block;
  cursor: pointer;
  text-decoration: underline;
  text-transform: none;
  width: 100%;
  &.photo-tip {
    padding-top: 5px;
  }
  a {
    text-decoration: none;
  }
  .tooltiptext-under,
  .tooltiptext-over {
    visibility: hidden;
    width: 100%;
    max-width: 550px;
    background-color: $color--white;
    color: $color--gray--medium--dark;
    border: 1px solid $color--gray--light;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 99;
    left: 0%;
    opacity: 0;
    transition: opacity 0.3s;
    line-height: 14px;
    overflow-y: scroll;
    height: 75px;
    @include breakpoint($bp--medium-up) {
      height: 120px;
    }
  }
  .tooltiptext-under {
    top: 100%;
  }
  .tooltiptext-over {
    bottom: 100%;
  }
  .tooltiptext-under::after {
    display: none;
    content: '';
    position: absolute;
    bottom: 100%;
    left: 17%;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent $color--gray--medium--dark transparent;
  }
  .tooltiptext-over::before {
    display: none;
    content: '';
    position: absolute;
    top: 100%;
    left: 17%;
    border-width: 5px;
    border-style: solid;
    border-color: $color--gray--medium--dark transparent transparent transparent;
  }
  &:hover .tooltiptext-under,
  &:hover .tooltiptext-over {
    visibility: visible;
    opacity: 1;
  }
}

.fb-disclaimer-error {
  color: $color--hot--brown;
  padding-bottom: 10px;
  text-align: $ldirection;
  text-transform: uppercase;
}

.fb-overlay-container {
  width: 265px;
  height: 40px;
  position: relative;
  margin: 0 auto;
  .fb-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }
}

.fb-overlay-social-info {
  width: 164px;
  height: 22px;
  position: relative;
  display: inline-block;
  .fb-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }
}
/* my-Appointments page */
.site-container {
  .appt-book {
    .my-appointments {
      .sign-in-container {
        .sign-in-form-container {
          .social-login {
            label {
              display: inline;
            }
            &__email-opt-in {
              text-align: left;
              margin-top: 24px;
            }
            .fb-disclaimer-container {
              margin-top: 20px;
              text-align: left;
            }
          }
          input {
            display: inline;
          }
        }
      }
    }
  }
}
