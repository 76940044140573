// Hiding b_functional section in privacy slider
$enable_cookie-img: false;

body {
  overflow: initial;
  div.AstuteBotLauncher {
    left: 0;
    .ToggleChat {
      display: none;
    }
    .ChatContainer {
      .ChatTitleBar {
        display: none;
      }
    }
  }
}

.checkout {
  .site-footer {
    display: none;
  }
}

.adpl {
  input[type='text'],
  input[type='email'],
  input[type='tel'],
  input[type='password'],
  select,
  textarea,
  .select2-container {
    &.error {
      border-color: $color--error;
    }
  }
  input[type='email'],
  input[type='tel'],
  input[type='password'],
  input[type='text'] {
    &:focus,
    &.active,
    &.input-label-mode {
      & + label:before {
        -webkit-transform: translateY(-2.41675em) translateY(-0.5px) scale(0.8, 0.8);
        transform: translateY(-2.41675em) translateY(-0.5px) scale(0.8, 0.8);
      }
    }
  }
}

.checkout-button--sticky {
  @include swap_direction(padding, 0 20px);
  color: $color--white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 64px;
  width: 100%;
  @if $fonts_update == false {
    display: inline-block;
    height: 60px;
    background-color: $color--black;
    font-family: $ano-bold_regular-font;
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
  }
}

[class*='icon--bars'],
[class*='icon--search'] {
  line-height: inherit;
}

.click-and-collect {
  .form_element {
    &.phone_1_container,
    &.phone_2_container {
      padding: 8px 0 10px 0;
    }
  }
  .local-collection,
  .store-collection {
    .checkout-buttons {
      color: white;
      text-align: center;
      padding: 0 20px;
      height: 60px;
      line-height: 64px;
      border-style: solid;
      border-color: white;
      border-width: 0;
      display: inline-block;
      background-color: black;
      text-transform: uppercase;
    }
  }
}

.click_and_collect_map {
  z-index: 99999;
  .map {
    position: initial !important;
  }
}

#colorbox {
  #cboxWrapper {
    #cboxContent {
      #cboxLoadedContent {
        .waitlist-iframe-wrapper {
          width: 95%;
          height: 85%;
          margin: 17px 7px 5px 7px;
        }
      }
    }
  }
}

.tiny-waitlist-overlay {
  h2.waitlist_header {
    font-size: 20px;
    margin: 5px 0px !important;
  }
  p.waitlist_message {
    margin-bottom: 0.5em;
  }
  .waitlist_thankyou_heading {
    font-size: 20px;
    margin: 80px 0 0px 0;
    text-align: center;
  }
  .waitlist_thankyou_message {
    margin-top: 10px;
    text-align: center;
  }
  .field {
    width: 100% !important;
    float: #{$ldirection};
    position: relative;
    .email_input {
      float: #{$ldirection};
      width: 100%;
      input {
        width: 68%;
        padding: 12px;
      }
    }
    .email_promo {
      float: #{$ldirection};
      width: 100%;
      padding-top: 10px;
      input[type='checkbox'] {
        -webkit-appearance: checkbox;
      }
      label {
        display: inline !important;
      }
    }
    .align-r {
      float: left;
      margin-left: 10px;
      position: absolute;
      top: 0px;
      #{$rdirection}: 10px;
      .input-btn {
        padding: 10px;
        height: auto;
        line-height: normal;
        width: 70px;
      }
    }
  }
}

#bt_settings div {
  &.bt-content {
    &.bt-content-mobile {
      font-size: 12px;
      h2 {
        font-size: 30px;
      }
      h3 {
        font-size: 21px;
      }
    }
  }
}

.site-container {
  #strictly_necessary,
  #cookie_funtional {
    color: #999;
  }
  .payment-properties {
    .payment_type.checkedEmpty {
      border: 1px solid #b84947;
    }
  }
  .past-purchases {
    .product__footer {
      .product-item {
        &__out-of-stock {
          float: right;
          width: 50%;
        }
      }
    }
  }
}

.search-results {
  &__results {
    .product--not-preorder {
      &.prod_inv_status-2 {
        .product__footer {
          .product__add-to-bag {
            display: none;
          }
        }
      }
    }
    .product--coming-soon {
      &.prod_inv_status-3 {
        .product__footer {
          .product__add-to-bag {
            display: none;
          }
        }
      }
    }
    .all-shaded-sold-out {
      &.prod_inv_status-7 {
        .product__footer {
          .product__add-to-bag {
            display: none;
          }
        }
      }
    }
  }
}

#updated_terms_notification {
  div {
    &.bt-content-mobile {
      position: fixed;
      bottom: 161px;
      width: 100%;
      border: 1px solid white;
      padding: 10px;
      background-color: black;
      z-index: 1000;
      font-size: 12px;
      line-height: 16px;
      color: white;
      a {
        color: #fff;
        font-weight: bold;
        border: 0;
      }
    }
    .bt-inner-content,
    .bt_contentarea {
      @include swap_direction(margin, 10px 0 10px 0);
      h4 {
        font-size: 1.8rem;
      }
    }
  }
}
/* Info popup styles for privacy policy */
.info-link {
  cursor: pointer;
  margin-bottom: 10px;
  display: inline-block;
  text-decoration: underline;
  border: none;
}

.font-clr {
  color: $color--white;
}

a.info-icon {
  cursor: pointer;
  background: $color--black;
  color: $color--white;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  display: block;
  text-transform: lowercase;
  padding: 1px 0px 0px 6px;
  text-decoration: none;
}

.info-message-txt {
  display: none;
}

#info-message-box {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10000;
  background-color: $color--white;
  padding: 20px;
  width: 90%;
  border: 1px solid $color--black;
  max-width: 500px;
  &:before {
    content: ' ';
    height: 0;
    position: absolute;
    width: 0;
    left: 0px;
    border: 10px solid transparent;
    border-bottom-color: $color--black;
    top: -10px;
    margin-top: -10px;
  }
  &.top-right-arrow {
    &:before {
      left: 478px; /* 500px minus Border 2px and Triangle 20px */
    }
  }
  #info-text {
    position: relative;
    span {
      cursor: pointer;
      border-bottom: 1px solid $color--gray--lightest;
      text-transform: uppercase;
    }
  }
  #info-close {
    position: absolute;
    top: -20px;
    right: -20px;
    font-size: 15px;
    width: 20px;
    height: 20px;
    text-align: center;
    cursor: pointer;
  }
}

#registration_short {
  .form-item.new-account__item.checkbox.password {
    .picker.picker-checkbox,
    .password-notice {
      float: left;
    }
    .password-notice {
      margin: 10px 0px 0px 10px;
    }
  }
}

.info-for-left,
.info-icon-right {
  float: left;
}

.info-icon-right {
  margin-left: 10px;
}

.site-footer {
  &--top {
    #home_page_mac_us_mobile {
      width: 100%;
    }
  }
}

.site-container {
  .collection-detail-formatter {
    overflow: hidden !important;
  }
  .custom-grid {
    overflow: hidden !important;
    .custom-grid {
      overflow: hidden !important;
    }
  }
}

body.section-fix {
  .site-container div {
    overflow: hidden !important;
  }
}

.product--shaded--single {
  .product__sku-details {
    .view-all-shades--mobile,
    .product__product-details-shade--single {
      display: none;
    }
  }
}

#bt_notification {
  .bt-cookie-section {
    float: left;
    width: 100%;
    .bt-buttons {
      float: left;
      width: 100%;
      margin-top: 10px;
      height: 52px;
      .btn-wrap {
        float: left;
        width: 48%;
        height: auto;
        margin-top: 0;
        text-align: center;
        &.accept-privacy {
          margin-right: 10px;
          a {
            &.btn {
              background-color: $color--white;
              color: $color--black;
              font-weight: 600;
              font-size: 15px;
              height: 44px;
              line-height: unset;
              padding: 16px 0;
            }
          }
        }
        &.personalize-privacy {
          a {
            padding: 16px 0;
            line-height: unset;
            height: 44px;
          }
        }
      }
    }
  }
}

.kit-container {
  @include swap_direction(margin, 0 0 0 36%);
  @include swap_direction(padding, 10px 0 0 0);
  clear: both;
  .checkout__sidebar & {
    @include swap_direction(margin, 0);
    @include swap_direction(padding, 0);
    clear: both;
  }
  .cart-item {
    &__desc-info {
      @include swap_direction(margin, 0 0 20px 0);
      @include swap_direction(padding, 0 0 20px 0);
      border-bottom: 1px solid $color--black;
      &:last-child {
        @include swap_direction(margin, 0);
        @include swap_direction(padding, 0);
        border-bottom: none;
      }
    }
    .swatch {
      border-radius: 50%;
      width: 22px;
      height: 22px;
      display: inline-block;
      position: relative;
      top: 6px;
    }
    .shade {
      @include swap_direction(padding, 0 0 0 5px);
      display: inline-block;
      &-label {
        display: none;
      }
    }
    .checkout__sidebar & {
      @include swap_direction(margin, 0);
    }
  }
}

.custom-kit-formatter {
  &__landing-content & {
    &__content-wrapper {
      top: 34%;
      #{$ldirection}: 50%;
    }
    &__start-cta {
      padding: 0 17px;
      height: 42px;
      background: $color--white;
      color: $color--black;
      font-size: 15px;
    }
  }
}

.viewcart {
  .payments-icon {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0 15px;
    margin: 10px auto 20px;
    .logo {
      background-position: center;
      margin: 0 7px;
      max-width: 40px;
      width: 100%;
      height: 27px;
    }
  }
}
