.sign-in-page {
  .section-head {
    &__link {
      .button {
        color: $color--white;
      }
      a:hover {
        text-decoration: underline;
      }
    }
  }
}

.header-search {
  &__icon svg {
    @include breakpoint($bp--large-up) {
      height: 50px;
    }
  }
}

.cr21-refresh {
  .site-header-formatter {
    &__utilities-content {
      .site-my-mac {
        &__link-icon {
          @include breakpoint($bp--large-up) {
            height: 66px;
          }
        }
      }
      .site-bag {
        &__link-icon {
          @include breakpoint($bp--large-up) {
            height: 32px;
          }
        }
      }
    }
  }
}

.viewcart {
  .payments-icon {
    .logo {
      &_visa {
        background: url('/media/export/images/checkout/payment_logo/visa.gif') no-repeat;
        background-size: 100%;
      }
      &_maestro {
        background: url('/media/export/images/checkout/payment_logo/cc_maestro.gif') no-repeat;
        background-size: 100%;
      }
      &_mc {
        background: url('/media/export/images/checkout/payment_logo/mc.gif') no-repeat;
        background-size: 100%;
      }
      &_paypal {
        background: url('/media/export/images/checkout/payment_logo/paypal.gif') no-repeat;
        background-size: 100%;
      }
      &_direct {
        background: url('/media/export/images/checkout/payment_logo/sofort.gif') no-repeat;
        background-size: 100%;
      }
    }
  }
}
