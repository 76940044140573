@if $loyalty-redesign == true {
  .account-nav {
    background-color: $color--dark--black;
    .account-nav__section {
      &.account-loyalty {
        @include breakpoint($bp--large-up) {
          padding: 15px 40px;
        }
      }
      .sidebar-title {
        font-family: $ano-bold_regular-font;
        font-size: 18px;
        a {
          font-size: 13px;
          letter-spacing: 0.5px;
          font-family: $helvetica-font;
          text-transform: initial;
        }
      }
      .account-profile__connected-accounts,
      .sidebar-menu__item {
        font-size: 13px;
        font-family: $helvetica-font;
        letter-spacing: 0.5px;
        text-transform: inherit;
        a {
          text-transform: inherit;
        }
      }
      .mac-select__account-panel__content {
        .loyalty__panel__content {
          width: 100%;
          .loyalty__points {
            @include breakpoint($bp--large-up) {
              float: #{$ldirection};
              text-align: #{$rdirection};
            }
            width: 32%;
            float: #{$rdirection};
            text-align: #{$ldirection};
            font-family: $ano-bold_regular-font;
            .points {
              &__text {
                @include breakpoint($bp--large-up) {
                  font-size: 12px;
                  padding-top: 4px;
                }
                padding-top: 8px;
                font-size: 14px;
              }
              &__value {
                @include breakpoint($bp--large-up) {
                  font-size: 28px;
                  line-height: 0.8;
                }
                @include breakpoint($bp--medium-portrait) {
                  font-size: 24px;
                }
                font-size: 34px;
                line-height: 0.6;
              }
            }
          }
        }
        &-mac__lover__id {
          font-family: $helvetica-font;
          font-size: 15px;
          text-transform: uppercase;
        }
        &-spend {
          @include breakpoint($bp--large-up) {
            font-size: 15px;
          }
          padding: 20px 0;
          font-family: $ano-bold_regular-font;
          font-size: 13px;
          line-height: 1;
        }
        &-details {
          @include breakpoint($bp--large-up) {
            padding-top: 20px;
          }
          font-size: 12px;
          font-family: $ano-bold_regular-font;
        }
        &-title {
          width: 68%;
          float: #{$ldirection};
          padding-top: 5px;
          .loyalty__step__level {
            @include breakpoint($bp--large-up) {
              padding-bottom: 0;
              font-size: 12px;
            }
            font-size: 14px;
            font-family: $ano-bold_regular-font;
          }
          .mac-select__color {
            &-tier1,
            &-tier2,
            &-tier3 {
              @include breakpoint($bp--large-up) {
                font-size: 20px;
              }
              @include breakpoint($bp--medium-portrait) {
                font-size: 18px;
              }
              font-size: 22px;
              font-family: $ano-bold_regular-font;
            }
            &-tier1 {
              color: $color--pink-tier1;
            }
            &-tier2 {
              color: $color--red-tier2;
            }
            &-tier3 {
              color: $color--gray-tier3;
            }
          }
        }
      }
    }
  }
}
