@if $password_strengthen {
  .password-field {
    &__info {
      @include breakpoint($bp--medium-up) {
        bottom: calc(50% - 50px);
      }
    }
  }
  .password-reset-page {
    .password-reset {
      .password-field__info-reset {
        @include breakpoint($width-xlarge) {
          top: 0;
          bottom: auto;
        }
      }
    }
  }
  .profile-password-update {
    &__rules {
      li {
        color: $color--gray--dark;
      }
    }
    @include breakpoint($bp--small-down) {
      .js-label-mode {
        & + label {
          -webkit-transform: translateY(-2.41675em) translateY(41.5px) scale(1, 0.8);
          -moz-transform: translateY(-2.41675em) translateY(41.5px) scale(1, 0.8);
          -o-transform: translateY(-2.41675em) translateY(41.5px) scale(1, 0.8);
          transform: translateY(-2.41675em) translateY(41.5px) scale(1, 0.8);
        }
      }
    }
  }
  .create-password {
    .password-field {
      @include breakpoint($bp--medium-up) {
        width: 25%;
        &__info {
          &-checkout {
            margin-left: 30%;
          }
        }
      }
    }
  }
  .domain-nl {
    #appt-book-my-appointments-no-signin {
      .password-field {
        &__info {
          @include breakpoint($bp--medium-up) {
            bottom: calc(10% - 50px);
          }
        }
      }
    }
  }
  #appt-book-my-appointments-no-signin {
    .password-field {
      &__info {
        @include breakpoint($bp--medium-up) {
          #{$ldirection}: 82%;
          width: auto;
          bottom: calc(42% - 50px);
        }
      }
    }
  }
  .profile-info {
    &__change_pwd_link {
      @include breakpoint($bp--medium-up) {
        margin-right: 25%;
      }
    }
  }
}
