.checkout {
  &.viewcart {
    .checkout-header {
      &__title {
        font-size: 18px;
      }
      #top-viewcart-buttons {
        display: block;
        .continue-buttons {
          width: auto;
          height: 110px;
          padding-right: 0px;
        }
      }
    }
    .cross_sell_container {
      width: 100%;
      padding-top: 10px;
      .cart_cross_sell_item {
        float: $ldirection;
        width: 50%;
        text-align: center;
        min-height: 200px;
      }
    }
    .viewcart-panel {
      border-bottom: 1px solid #dbdbdb;
      padding: 1em 0;
      ul#form--errors {
        padding-bottom: 20px;
        padding-top: 20px;
      }
    }
    .favorites-panel__title {
      display: block;
    }
    #bottom-viewcart-buttons {
      margin-top: 20px;
    }
  }
  .cart-item {
    .product {
      &__thumb {
        width: 35%;
      }
      &__desc {
        position: relative;
        width: 65%;
      }
    }
    .cart-item {
      &__product-name {
        padding-#{$rdirection}: 18px;
        margin-bottom: 0.1em;
      }
      &__product-price {
        @if $fonts_update == false {
          font-family: $ano-bold_regular-font;
          font-weight: normal;
          text-transform: uppercase;
          font-size: 1.5rem;
          margin-top: 0.25em;
          line-height: 13px;
          margin-bottom: 0.7em;
        }
      }
      &__select__container {
        .cart-item__qty {
          float: $ldirection;
          clear: none;
          width: 28%;
          max-width: 35%;
          @include swap_direction(margin, 0);
          @include swap_direction(padding, 0);
        }
      }
    }
    .selectbox,
    select:valid,
    select:required:valid {
      height: 30px;
      line-height: 28px;
    }
    .remove_link {
      position: absolute;
      top: 12px;
      #{$rdirection}: 0;
      text-decoration: none;
      border: 0;
      font-size: 16px;
    }
  }
  .order-summary-panel {
    &__title {
      font-size: 19px;
      border-bottom: 1px solid $color--gray--light;
      padding-bottom: 10px;
      margin-bottom: 10px;
      display: block;
    }
    .order-summary {
      &__content {
        .label,
        .value {
          @if $fonts_update == false {
            font-family: $tstar-font;
            font-size: 13px;
            margin-bottom: 0.5em;
          }
          &.total {
            @include swap_direction(padding, 18.5px 10px);
            background: $color--gray--white;
            margin-bottom: 0;
            @if $fonts_update == false {
              font-size: 19px;
              font-family: $ano-bold_regular-font;
            }
          }
        }
      }
    }
  }
  &.samples {
    .sample-products {
      .product__desc {
        .product_details {
          margin-bottom: 1em;
        }
        .sample-select-container {
          @include swap_direction(padding, 0);
          .picker-handle {
            display: none;
          }
          .sample-select-button {
            @include swap_direction(padding, 0);
          }
          .picker {
            @include swap_direction(margin, 0);
          }
        }
      }
      .deactivate {
        .btn {
          &:hover {
            color: $color--black;
            text-decoration: none;
          }
        }
      }
    }
  }
  .signin {
    .checkout-header {
      border-bottom: none;
    }
    #forgot-pass {
      margin-bottom: 15px;
      text-align: $rdirection;
    }
  }
  &.review {
    .review-panel {
      .shipping-address-display,
      .gift-options-display {
        .content-display {
          float: $ldirection;
          margin-bottom: 1.5em;
        }
        .change-link__container {
          float: $rdirection;
        }
        .checkout__subtitle {
          border-bottom: 1px solid $color--gray--lightest;
          padding-bottom: 10px;
          margin-bottom: 10px;
          display: block;
        }
      }
    }
    .checkout__sidebar {
      padding-bottom: 0;
      .order-summary-panel {
        background: $color--white;
      }
      .accordionPanel {
        @include swap_direction(margin, 0 10px);
        @if $fonts_update == false {
          .offer-code-panel__title {
            font-size: 1.7rem;
          }
        }
      }
      &.display-footer {
        padding-bottom: 75px;
      }
    }
  }
  .review-submit,
  .shipping_checkout_btn,
  .samples-panel__continue-checkout--button {
    position: fixed;
    bottom: 0;
    #{$ldirection}: 0;
    width: 100%;
    @include swap_direction(margin, 0);
    @include swap_direction(padding, 0 15px 15px 15px);
    background: $color--white;
    z-index: 100;
    a.button {
      &:visited,
      &:active {
        color: $color--white;
      }
    }
  }
  @include breakpoint(orientation portrait) {
    .samples-panel {
      &__continue-checkout--button {
        position: relative;
      }
    }
  }
  .checkout {
    .offer-code__input {
      @include swap_direction(padding, 1em 0);
    }
    @if $fonts_update == true {
      .ins-preview-wrapper-971 {
        div#editable-text-1454703450633 {
          font-family: $ano-black_regular-font;
          font-size: 18px;
        }
      }
      .ins-preview-wrapper-975 {
        #editable-text-1454703450633 {
          font-family: $ano-black_regular-font;
          font-size: 18px;
        }
      }
    }
  }
}

.accordionPanel {
  form {
    &#offer_code {
      .invalid_marker,
      .valid_marker {
        bottom: initial;
        margin-top: 10px;
      }
    }
  }
}

#shipping-address-info-panel {
  .click-and-collect {
    .invalid_marker,
    .valid_marker {
      bottom: initial;
      right: 25px;
      margin-top: 10px;
    }
  }
}

.trans_detail_item {
  .offer-code__input {
    .invalid_marker,
    .valid_marker {
      margin-right: 40px;
    }
  }
}

.payent-select {
  .payment-properties {
    .invalid_marker,
    .valid_marker {
      bottom: initial;
      right: 10px;
    }
  }
}

#completed_checkout_registration {
  .pc_email_container {
    label {
      display: block;
    }
  }
}

#confirm {
  a.info-link[message-in='confirm-sign-notice'] {
    float: none;
    border-bottom: 0px;
    text-decoration: underline $color--gray--lighter;
  }
}

#map-foreground-node {
  .map-container {
    .location-info-windows,
    .map-info-window {
      .location {
        .button {
          text-align: center;
          .select-access-point {
            width: 11em;
            padding: 0;
          }
        }
      }
    }
    .map-info-window {
      .location {
        .button {
          .get-directions {
            width: 10em;
            padding: 0;
          }
        }
        .address {
          p {
            line-height: 1.2em !important;
          }
        }
      }
    }
    .directions {
      .select-access-point_dir {
        position: relative;
        top: 50px;
        margin-#{$ldirection}: 10px;
        line-height: 40px;
        height: 40px;
      }
    }
  }
}

#colorbox {
  &.max_purchase {
    div {
      &.narrowed_body_copy {
        p,
        ul,
        li,
        table,
        strong {
          &:not(.utilisation) {
            display: none;
          }
        }
        h5 {
          display: none;
        }
        .utilisation {
          padding-top: 25px;
        }
      }
    }
  }
}
